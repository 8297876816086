import React, {useEffect, useRef, useState} from "react";
import styles from '../../FabricSet/FabricSet.module.scss';
import closeIcon from "../../../../../assets/icons/closeCross.svg";
import GlobalCalculator from "../../../../Common/GlobalCalculator/GlobalCalculator";

const CustomSizePopup = ({show, close, onChangeOptions, componentIndex, form}) => {
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [currentFocus, setCurrentFocus] = useState(null);
    const [lastEditedInput, setLastEditedInput] = useState('');
    const widthInputRef = useRef(null);
    const lengthInputRef = useRef(null);

    const [customSize, setCustomSize] = useState(null);

    const onCustomSizeSave = () => {
        setCustomSize(`${width}x${length}`);
        onChangeOptions(`${width}x${length}`, componentIndex, 'custom_size');
        close();
    }

    const handleWidthChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = width + value;
        if (width === '0') {
            potentialNewValue = value;
        }
        if (potentialNewValue.length <= 3 && /^\d{1,3}$/.test(potentialNewValue)) {
            let numericValue = parseInt(potentialNewValue, 10);
            if (numericValue <= 999) {
                setWidth(potentialNewValue);
                setLastEditedInput('width');
                if (potentialNewValue.length === 3 || numericValue === 0) {
                    lengthInputRef.current.focus();
                }
            }
        }
    }

    const handleWidthDelete = () => {
        let newValue = width.slice(0, width.length - 1);
        setWidth(newValue);
    }

    const handleLengthChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = length + value;
        if (potentialNewValue.length <= 3 && /^\d{1,3}$/.test(potentialNewValue)) {
            let numericValue = parseInt(potentialNewValue, 10);
            if (numericValue <= 999) {
                setLength(potentialNewValue);
                setLastEditedInput('length');
            }
        }
    }

    const handleLengthDelete = () => {
        let newValue = length.slice(0, length.length - 1);
        setLength(newValue);
    }

    const handleCustomSizeChange = (e) => {
        if ((currentFocus === 'width' && width.length !== 3) || (!currentFocus && width.length !== 3)) {
            handleWidthChange(e)
        } else if (currentFocus === 'length' || (!currentFocus && width.length === 3) || (currentFocus === 'width' && width.length === 3)) {
            handleLengthChange(e);
        }
    }

    const handleCustomSizeDelete = () => {
        if (currentFocus === 'width' || (currentFocus === 'length' && length.length === 0)) {
            handleWidthDelete();
            widthInputRef.current.focus();
        } else if (currentFocus === 'length' && length.length !== 0) {
            handleLengthDelete();
        } else if (!currentFocus) {
            if (lastEditedInput === 'width') {
                handleWidthDelete();
            } else if (lastEditedInput === 'length' && length.length !== 0) {
                handleLengthDelete();
            } else if (lastEditedInput === 'length' && length.length === 0) {
                handleWidthDelete();
            }
        }
    }

    useEffect(() => {
        let initialCustomSize = form?.template_components[componentIndex]?.custom_size;
        if (initialCustomSize) {
            setCustomSize(initialCustomSize);
            let initialWidth = initialCustomSize.split('x')[0];
            setWidth(initialWidth);
            let initialLength = initialCustomSize.split('x')[1];
            setLength(initialLength);
            setCurrentFocus('length');
            setLastEditedInput('length');
        }
    }, [componentIndex]);

    return (
        <div
            className={`${show ? `${styles.globalPopupActive}` : styles.globalPopup}`}
            onClick={(e) => e.stopPropagation()}>
            <div className={styles.header}>
                <p className={styles.header__title}>Розмір ковдри</p>
                <div className={styles.header__close} onClick={() => close()}>
                    <span>Закрити</span>
                    <button>
                        <img src={closeIcon} alt=""/>
                    </button>
                </div>
            </div>
            <div className={styles.globalPopup__content}>
                <GlobalCalculator handleChange={handleCustomSizeChange} handleDelete={handleCustomSizeDelete}
                                  onSubmit={() => onCustomSizeSave()}>
                    <div className={styles.sizeInputWrap}>
                        <input id='custom_item_width' name="custom_item_width" value={width}
                               className={'baseInput'} type="text" readOnly onFocus={() => setCurrentFocus('width')}
                               ref={widthInputRef}/>
                        <span>х</span>
                        <input id='custom_item_length' name="custom_item_length" value={length}
                               className={'baseInput'} type="text" readOnly onFocus={() => setCurrentFocus('length')}
                               ref={lengthInputRef}/>
                    </div>
                </GlobalCalculator>
            </div>
        </div>
    )
}

export default CustomSizePopup