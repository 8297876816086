import React, {useEffect, useState} from "react";
import styles from './Order.module.scss';
import paste from '../../../assets/icons/pasteIcon.svg';
import {useFormik} from 'formik';
import PayCharger from "./PayCharger";
import DateTimeField from "../DateTimeField/DateTimeField";
import StatusPay from "./StatusPay";
import DiscountField from "./DiscountField";
import UrgentlyField from "./UrgentlyField";
import {getAccessToken, updateCustomer, updateOrder, updatePayment} from "../../../api/api";
import {useAppSelector} from "../../../hooks/redux";
import {getTotalPrice, getUser} from "../../../store/selectors";
import Parser from "../../Common/Parser/Parser";

const OrderCreate = ({order}) => {
    const manager = useAppSelector(getUser);
    const [payType, setPayType] = useState('');
    const [date, setDate] = useState('Дата оплати');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [discountType, setDiscountType] = useState('')
    const [isUrgently, setIsUrgently] = useState(false);
    const [popup, setPopup] = useState(null);
    const [status, setStatus] = useState(null);
    const [saveData, setSaveData] = useState('');
    const [percentInputValue, setPercentInputValue] = useState(0);
    const [UAHInputValue, setUAHInputValue] = useState(0);
    const totalPrice = useAppSelector(getTotalPrice);

    useEffect(() => {
        if (order && order.payment) {
            if (payType !== order.payment.type) {
                setPayType(order.payment.type);
            }
            if (isUrgently !== order.payment.is_approved) {
                setIsUrgently(order.payment.is_approved);
            }
            if (order.payment && order.payment.paid_time && date !== `${order.payment.paid_time.substring(8, 10)}.${order.payment.paid_time.substring(5, 7)}.${order.payment.paid_time.substring(0, 4)}`) {
                setDate(`${order.payment.paid_time.substring(8, 10)}.${order.payment.paid_time.substring(5, 7)}.${order.payment.paid_time.substring(0, 4)}`);
            }
            if (order.payment && order.payment.paid_time && hours !== order.payment.paid_time.substring(11, 13) && minutes !== order.payment.paid_time.substring(14, 16)) {
                setHours(order.payment.paid_time.substring(11, 13))
                setMinutes(order.payment.paid_time.substring(14, 16))

            }
            if (discountType !== order.payment.discount_type) {
                setDiscountType(order.payment.discount_type);
                if (order.payment.discount_type === 'VAL' && order.payment.discount_amount) {
                    setUAHInputValue(order.payment.discount_amount)
                } else if (order.payment.discount_type === 'PERC' && order.payment.discount_amount) {
                    setPercentInputValue(order.payment.discount_amount);
                }
            }
            if (status !== order.payment.status && order.payment.status !== 'NONE') {
                setStatus(order.payment.status);
            }
        }

    }, [order])

    const formValues = {
        userUrl: order.customer && order.customer.url ? order.customer.url : '',
        nickName: order.customer && order.customer.username ? order.customer.username : '',
        first_name: order.customer && order.customer.first_name ? order.customer.first_name : '',
        last_name: order.customer && order.customer.last_name ? order.customer.last_name : '',
        middle_name: order.customer && order.customer.middle_name ? order.customer.middle_name : '',
        phone: order.customer && order.customer.phone ? order.customer.phone : '',
        post_paid_amount: order.payment && order.payment.post_paid_amount ? order.payment.post_paid_amount : '',
        discount_amount: order.payment && order.payment.discount_amount ? order.payment.discount_amount : '',
        sum: totalPrice ? totalPrice : order?.payment?.amount ? order.payment.amount : '',
        comment: order.comment ? order.comment : '',
        amountDue: '',
    }

    const {handleSubmit, handleChange, values, setValues, setFieldValue} = useFormik({
        initialValues: formValues,
    });

    const auditDates = (value, fieldName, type) => {
        if (saveData !== value && type === 'customer') {
            getAccessToken().then((token) => {
                updateCustomer(token, order.id, {[fieldName]: value}).then((res) => console.log(res))
            });
        } else if (saveData !== value && type === 'payment') {
            getAccessToken().then((token) => {
                updatePayment(token, order.id, {[fieldName]: value}).then((res) => console.log('ok'))
            });
        } else if (saveData !== value && type === 'order') {
            getAccessToken().then((token) => {
                updateOrder(token, order.id, manager.id, {[fieldName]: value}).then((res) => console.log(res))
            });
        }
    }

    const calculateTotalSum = () => {
        if (!values.discount_amount) {
            setFieldValue("amountDue", String(values.sum));
            return;
        }

        let totalSum = 0;

        if (discountType === 'PERC') {
            totalSum = values.sum - ((values.sum * values.discount_amount) / 100);
        } else if (discountType === 'VAL') {
            totalSum = values.sum - values.discount_amount;
        }

        setFieldValue("amountDue", String(Math.ceil(totalSum)));
    }

    const handleURLChange = (e) => {
        if (e.target.value) {
            const nickname = e.target.value.match(/(?:https?:\/\/)?(?:www.)?(?:instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/);
            if (Array.isArray(nickname) && nickname[1]) {
                setFieldValue("nickName", nickname[1]);
            } else {
                setFieldValue("nickName", "");
            }
        } else {
            setFieldValue("nickName", "");
        }

        handleChange(e);
    }

    const updateData = (value) => {
        getAccessToken().then((token) => {
            updateCustomer(token, order?.id, value).then((res) => console.log(res))
        });
    }

    useEffect(() => {
        calculateTotalSum();
    }, [values.sum, discountType, percentInputValue, UAHInputValue]);

    const onSubmit = (value) => {
        auditDates(value, 'status', 'order')
    }

    return (
        <div className={styles.order}>
            <form onSubmit={handleSubmit}>
                <h2 className={styles.order__title}>Замовлення</h2>
                <div className={styles.order__content}>
                    <p className={styles.order__header}>
                        <span>{order.id}</span>
                        {order && order.created && (
                            <span>{`${order.created.substring(8, 10)}.${order.created.substring(5, 7)}.${order.created.substring(0, 4)}`}</span>
                        )}
                    </p>
                    <div className={styles.form}>
                        <div className={styles.form__userUrl}>
                            <input
                                placeholder={'User URL'}
                                className={'baseInput'}
                                id="userUrl"
                                name="userUrl"
                                type="text"
                                value={values.userUrl}
                                onChange={handleURLChange}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => {
                                    getAccessToken().then((token) => {
                                        updateCustomer(token, order.id, {
                                            url: e.target.value,
                                            username: values.nickName
                                        })
                                            .then((res) => console.log(res))
                                    });
                                }}
                            />
                            <button>
                                <img src={paste} alt=""/>
                            </button>
                        </div>
                        <div>
                            <input
                                placeholder={'Нік'}
                                className={'baseInput'}
                                aria-label={'username'}
                                id="nickName"
                                name="nickName"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'username', 'customer')}
                                value={values.nickName}
                            />
                        </div>
                        <div className={styles.form__name}>
                            <input
                                placeholder={'І`мя'}
                                className={'baseInput'}
                                aria-label={'first_name'}
                                id={'first_name'}
                                name="first_name"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'first_name', 'customer')}
                                value={values.first_name}
                            />
                            <input
                                placeholder={'Прізвище'}
                                className={'baseInput'}
                                aria-label={'last_name'}
                                id="last_name"
                                name="last_name"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'last_name', 'customer')}
                                value={values.last_name}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={'По батькові'}
                                className={'baseInput'}
                                aria-label={'middle_name'}
                                id="middle_name"
                                name="middle_name"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'middle_name', 'customer')}
                                value={values.middle_name}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={'Телефон замовника'}
                                className={'baseInput'}
                                aria-label={'phone'}
                                id="phone"
                                name="phone"
                                type="text"
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'phone', 'customer')}
                                value={values.phone}
                            />
                        </div>
                        <div>
                            <input
                                placeholder={'Сума передоплати(післяоплата)'}
                                className={'baseInput'}
                                id="post_paid_amount"
                                name="post_paid_amount"
                                type="number"
                                value={values.post_paid_amount}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'post_paid_amount', 'payment')}
                            />
                        </div>
                        <PayCharger payType={payType} setPayType={setPayType} auditDates={auditDates}/>
                        <DateTimeField date={date} setDate={setDate} setPopup={setPopup} popup={popup}
                                       auditDates={auditDates} hours={hours} setHours={setHours} minutes={minutes} setMinutes={setMinutes}/>
                        <StatusPay setPopup={setPopup} popup={popup} status={status} setStatus={setStatus}
                                   auditDates={auditDates}/>
                        <div>
                            <input
                                placeholder={'Сума замовлення'}
                                className={'baseInput'}
                                id="sum"
                                name="sum"
                                type="number"
                                value={values.sum}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(Number(e.target.value))}
                                onBlur={(e) => auditDates(Number(e.target.value), 'amount', 'payment')}
                            />
                        </div>
                        <DiscountField
                            percentInputValue={percentInputValue}
                            setPercentInputValue={setPercentInputValue}
                            UAHInputValue={UAHInputValue}
                            setUAHInputValue={setUAHInputValue}
                            discountType={discountType}
                            setDiscountType={setDiscountType}
                            popup={popup}
                            setPopup={setPopup}
                            setDiscountValue={(discountValue) => setFieldValue("discount_amount", discountValue)}
                            orderId={order?.id}
                            getAccessToken={getAccessToken}
                            updatePayment={updatePayment}
                        />
                        <div className={styles.closedInput}>
                            <input
                                placeholder={'Сума знижки(авто)'}
                                className={'baseInput'}
                                id="discount_amount"
                                name="discount_amount"
                                type="number"
                                value={discountType === 'VAL'
                                    ? values.discount_amount
                                    : Math.floor((values.sum * values.discount_amount) / 100) || ""
                                }
                                readOnly
                            />
                        </div>
                        <div className={styles.closedInput}>
                            <input
                                placeholder={'Сума до сплати'}
                                className={'baseInput'}
                                id="amountDue"
                                name="amountDue"
                                type="number"
                                value={values.amountDue}
                                readOnly
                            />
                        </div>
                        <UrgentlyField setIsUrgently={setIsUrgently} isUrgently={isUrgently} auditDates={auditDates}/>
                        <div>
                            <textarea
                                placeholder={'Коментар до замовлення'}
                                className={'baseInput'}
                                aria-label={'comment'}
                                id="comment"
                                name="comment"
                                value={values.comment}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                onFocus={(e) => setSaveData(e.target.value)}
                                onBlur={(e) => auditDates(e.target.value, 'comment', 'order')}
                            />
                        </div>
                    </div>
                    <div className={styles.action}>
                        <button type={'submit'} className={'globalBtn'} onClick={() => onSubmit('PROD')}
                                disabled={values.first_name.length < 3 || values.last_name.length < 3 || values.phone.length < 9 || payType === ''}>
                            <span>На виробництво</span>
                        </button>
                    </div>
                </div>
            </form>
            <Parser
                values={values}
                setValues={(newValues) => setValues(prevValues => ({ ...prevValues, ...newValues }))}
                updateData={updateData}
            />
        </div>
    )
}

export default OrderCreate;