import { useState } from 'react';
import styles from './FabricSize.module.scss';
import {useAppSelector} from "../../../../hooks/redux";
import {getLastOrder} from "../../../../store/selectors";

export default function FabricSizeSecond({ setComponents, setForm, product, isEdit}) {
    const [activeSecondSize, setActiveSecondSize] = useState(null);
    const lastOrderId = useAppSelector(getLastOrder);
  /*  console.log(product);*/

    const handleSelectSecondSize = (idx, components) => {
        setActiveSecondSize(idx);
        setComponents(components);
        setForm({
            order_id: Number(lastOrderId),
            fabric_id: product.kit[idx]?.fabric?.id,
            template_components: product.kit[idx].components.map((item) => {
                return {
                    kit_template_id: product.kit[idx]?.id,
                    component_template_id: item?.id,
                    option: [],
                }
            }),
            additional_components: [],
            custom_size: "",
            option: [],
        })
    }

    console.log(product?.kit);

    return (
        <div className={styles.size}>
            <div className={styles.size__kitRow}>
                {product?.kit.map((el, index) => (
                    <div
                        key={index}
                        className={`${styles.item} ${styles.kitItem} ${!isEdit? activeSecondSize === index ? styles.activeStandard : "" : styles.activeStandard}`}
                        onClick={!isEdit ? () => handleSelectSecondSize(index, el) : null}
                    >
                        <div className={`${styles.item__panel} ${styles.item__kitPanel}`}>
                            <span>{el.short_name}</span>
                        </div>
                        <p className={styles.item__fullName}>{el.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
