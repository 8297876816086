import React, {useEffect, useRef, useState} from "react";
import styles from './Menu.module.scss';
import {Link} from 'react-router-dom';
import mainIcon from '../../assets/icons/mainIcon.svg';
import mainBrownIcon from '../../assets/icons/mainBrownIcon.svg';
import fabricsBrownIcon from '../../assets/icons/fabricsBrownIcon.svg';
import fabricsIcon from '../../assets/icons/fabricsIcon.svg';
import novaMailIcon from '../../assets/icons/novaPoshta.svg';
import ukrMailIcon from '../../assets/icons/ukrPoshta.svg';
import productsIcon from '../../assets/icons/products.svg';
import productsBrownIcon from '../../assets/icons/productsBrown.svg';
import storageBrownIcon from '../../assets/icons/storageBrownIcon.svg';
import storageIcon from '../../assets/icons/storageIcon.svg';
import authIcon from '../../assets/icons/auth.svg';
import {useLocation, useNavigate} from "react-router";
import statusIcon from "../../assets/icons/statusWhite.svg";
import statusBrownIcon from "../../assets/icons/statusBrown.svg";
import arrIcon from "../../assets/icons/arrowDown.svg";
import backIcon from "../../assets/icons/back.svg";
import logoutIcon from '../../assets/icons/logout.svg';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {setIsActivePopup, setLastOrders} from "../../store/main-slice";
import GlobalPopup from "../Common/GlobalPopup/GlobalPopup";
import DeliveryChange from "../ClientPage/Popups/DeliveryChange/DeliveryChange";
import {getAccessToken, logout, setNewOrder} from "../../api/api";
import {getUser} from "../../store/selectors";
import {setAuth} from "../../store/account-slice";

const MainMenu = () => {
    const [isDeliveryMenu, setIsDeliveryMenu] = useState(false)
    const [isDeliveryNP, setIsDeliveryNP] = useState(true)
    const {pathname} = useLocation();
    const [menuPosition, setMenuPosition] = useState(2);
    const [touchStartX, setTouchStartX] = useState(0);
    const [touchEndX, setTouchEndX] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const {id} = useAppSelector(getUser);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const menuRef = useRef(null);
    const leftHiddenLinkRef = useRef(null);
    const rightHiddenLinkRef = useRef(null);


    const onOrder = () => {
        resetHiddenButtons();
        if (id) {
            getAccessToken().then((token) => {
                setNewOrder(token, id).then((response) => {
                    if (response && response.id) {
                        dispatch(setLastOrders(response.id))
                        setIsDeliveryMenu(true);
                        navigate(`/order/${response.id}`);
                    }
                })
            })
        }

    }

    useEffect(() => {
        if (pathname === '/' || pathname === '/fabrics/' || pathname === '/storage/') {
            setIsDeliveryMenu(false);
        } else  {
            setIsDeliveryMenu(true);
        }
    }, [pathname])

    const changeDelivery = () => {
        setShowPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const onConfirm = () => {
        setIsDeliveryNP(!isDeliveryNP);

        setTimeout(() => {
            isDeliveryNP ? navigate('/deliveryUKR/') : navigate('/deliveryNP/');
            dispatch(setIsActivePopup(false));
            setShowPopup(false);
        },20)
    }

    const onCancel = () => {
        dispatch(setIsActivePopup(false));
        setShowPopup(false);
    }

    /*swipe*/

    const handleTouchStart = (e) => {
        setTouchStartX(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        //left & right swipe (full version, temporarily commented)

       /* if (touchStartX - touchEndX < -50 && menuPosition > 1) {
            setMenuPosition(menuPosition - 1);
        }  else if (touchStartX - touchEndX > 50 && menuPosition < 3) {
            setMenuPosition(menuPosition + 1);
        }*/

        /*only right icon*/
        if (touchStartX - touchEndX > 50 && menuPosition < 3) {
            setMenuPosition(menuPosition + 1);
        }
        /*reset to center*/
        if (touchStartX - touchEndX < -50 && menuPosition === 3) {
            setMenuPosition(2);
        }
    };

    const resetHiddenButtons = () => {
        setMenuPosition(2);
    }

    const onLogOut = () => {
        resetHiddenButtons();
        logout().then((res) => {
            if (res && res === 'ok') {
                setTimeout(() => {
                    dispatch(setAuth(false));
                }, 50)
            }
        })
    }

    const onReturn = () => {
        resetHiddenButtons();
        navigate('/');
    }

    //outside click detection for menu swipe
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !leftHiddenLinkRef.current.contains(event.target) &&
                !rightHiddenLinkRef.current.contains(event.target)) {
                setMenuPosition(2);
            }
        };

        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <>
            <div className={`${styles.userMenu} ${styles.swipeMenu}`}
                 style={{left: menuPosition === 1 ? 'calc(50% + 67px)' : menuPosition === 3 ? 'calc(50% - 67px)' : '50%'}}
                 onTouchStart={handleTouchStart}
                 onTouchMove={handleTouchMove}
                 onTouchEnd={handleTouchEnd}
                 ref={menuRef}>
                {!isDeliveryMenu
                    ? <>
                        <Link to={'/'}
                              className={pathname === '/' ? styles.userMenu__linkActive : styles.userMenu__link}
                              onClick={() => resetHiddenButtons()}>
                            <img src={pathname === '/' ? mainBrownIcon : mainIcon} alt=""/>
                            <span>Замовлення</span>
                        </Link>
                        <Link to={'/fabrics/'}
                              className={pathname === '/fabrics/' ? styles.userMenu__linkActive : styles.userMenu__link}
                              onClick={() => resetHiddenButtons()}>
                            <img src={pathname === '/fabrics/' ? fabricsBrownIcon : fabricsIcon} alt=""/>
                            <span>Тканини</span>
                        </Link>
                        <Link to={'/storage/'}
                              className={pathname === '/storage/' ? styles.userMenu__linkActive : styles.userMenu__link}
                              onClick={() => resetHiddenButtons()}>
                            <img src={pathname === '/storage/' ? storageBrownIcon : storageIcon} alt=""/>
                            <span>Склад</span>
                        </Link>
                        <button onClick={() => onOrder()} className={styles.userMenu__link}>
                            <img src={authIcon} alt=""/>
                            <span>Замовлення +</span>
                        </button>
                    </>
                    :
                    <>
                        <Link to={'/order/'}
                              className={pathname.substring(0, 7) === '/order/' ? styles.userMenu__linkActive : styles.userMenu__link}
                              onClick={() => resetHiddenButtons()}>
                            <img src={pathname.substring(0, 7) === '/order/' ? mainBrownIcon : mainIcon} alt=""/>
                            <span>Замовлення</span>
                        </Link>
                        {isDeliveryNP ? (
                            <Link to={'/deliveryNP/'}
                                  onClick={() => pathname === '/deliveryNP/' || pathname === '/deliveryUKR/' ? changeDelivery() : resetHiddenButtons()}
                                  className={pathname === '/deliveryNP/' || pathname === '/deliveryUKR/' ? styles.userMenu__linkActive : styles.userMenu__link}>
                                <img src={pathname === '/deliveryNP/' ? novaMailIcon : novaMailIcon} alt=""/>
                                <span>Доставка</span>
                            </Link>
                        ) : (
                            <Link to={'/deliveryUKR/'}
                                  onClick={() => pathname === '/deliveryNP/' || pathname === '/deliveryUKR/' ? changeDelivery() : resetHiddenButtons()}
                                  className={pathname === '/deliveryNP/' || pathname === '/deliveryUKR/' ? styles.userMenu__linkActive : styles.userMenu__link}>
                                <img src={pathname === '/deliveryUKR/' ? ukrMailIcon : ukrMailIcon} alt=""/>
                                <span>Доставка</span>
                            </Link>
                        )}
                        <Link to={'/products/'}
                              className={pathname === '/products/' ? styles.userMenu__linkActive : styles.userMenu__link}
                              onClick={() => resetHiddenButtons()}>
                            <img src={pathname === '/products/' ? productsBrownIcon : productsIcon} alt=""/>
                            <span>Товари</span>
                        </Link>
                        <Link to={'/order_status/'}
                              className={pathname === '/order_status/' ? styles.userMenu__linkActive : styles.userMenu__link}
                              onClick={() => resetHiddenButtons()}>
                            <img src={pathname === '/order_status/' ? statusBrownIcon : statusIcon} alt=""/>
                            <span>Статус</span>
                        </Link>
                    </>
                }
            </div>
            <div className={styles.back}>
                <button onClick={null} className={styles.hiddenLeftLink}
                      style={{left: menuPosition === 1 ? '0' : '-67px'}} ref={leftHiddenLinkRef}>
                    <img src={arrIcon} alt="" className={styles.rotated}/>
                </button>
                <button onClick={() => isDeliveryMenu? onReturn() : onLogOut()}
                        className = {`${styles.hiddenRightLink} ${isDeliveryMenu ? styles.returnLink : styles.logoutLink}`}
                      style={{right: menuPosition === 3 ? '0' : '-67px'}} ref={rightHiddenLinkRef}>
                    <img src={isDeliveryMenu? backIcon : logoutIcon} alt=""/>
                    <span>{isDeliveryMenu? 'Назад' : 'Вийти'}</span>
                </button>
            </div>
            {showPopup && (
                <GlobalPopup title={'Зміна доставки'} onClose={() => setShowPopup(false)}>
                    <DeliveryChange onConfirm={onConfirm} onCancel={onCancel}/>
                </GlobalPopup>
            )}

        </>

    )
}

export default MainMenu;