import React, { useEffect, useState } from "react";
import styles from './Parser.module.scss';

import data from './parserData.json';
import closeCross from '../../../assets/icons/closeCross.svg';
import arrowDown from '../../../assets/icons/arrowDown.svg';
import plusIcon from '../../../assets/icons/plus.svg';
import paste from '../../../assets/icons/pasteIcon.svg';
import editIcon from '../../../assets/icons/editIcon.svg';
import applyIcon from '../../../assets/icons/statusBrown.svg';
import { getAccessToken, getCities } from "../../../api/api";

const smoothScrollToBottom = (duration = 150) => {
    const start = window.scrollY;
    const end = document.documentElement.scrollHeight - window.innerHeight;
    const distance = end - start;
    const step = distance / (duration / 16);

    const scroll = () => {
        window.scrollBy(0, step);
        if (window.scrollY < end) {
            requestAnimationFrame(scroll);
        }
    };

    scroll();
};

const Parser = ({ values, setValues, updateData = () => { }, parserStyles = {} }) => {
    const initialFormValues = {
        ...(values?.first_name !== undefined && { first_name: values.first_name }),
        ...(values?.last_name !== undefined && { last_name: values.last_name }),
        ...(values?.middle_name !== undefined && { middle_name: values.middle_name }),
        ...(values?.phone !== undefined && { phone: values.phone }),
        ...(values?.city !== undefined && { city: values.city })
    };
    const [parserData, setParserData] = useState(null);
    const [clientInfo, setClientInfo] = useState("");
    const [parsedValues, setParsedValues] = useState(initialFormValues);
    const [isParsed, setIsParsed] = useState(false);
    const [isParserOpened, setIsParserOpened] = useState(false);
    const [parsedCityName, setParsedCityName] = useState(null);
    const [isEdit, setIsEdit] = useState(true);
    const [isAddValue, setIsAddValue] = useState(false);
    const [newValue, setNewValue] = useState({});

    useEffect(() => {
        setParserData(data);
    }, [])

    useEffect(() => {
        if (isParserOpened) {
            smoothScrollToBottom();
        }
    }, [isParserOpened])

    useEffect(() => {
        setParsedValues(initialFormValues);
    }, [values?.first_name, values?.last_name, values?.middle_name, values?.phone, values?.city])

    useEffect(() => {
        if (parsedCityName) {
            setValues({ ...parsedValues, city: parsedCityName });
            setParsedCityName(null);
        }
    }, [parsedCityName])

    const fetchCity = async (words) => {
        if (!Array.isArray(words) || !words.length) return;

        try {
            const token = await getAccessToken();
            if (!token) throw new Error('Access token is missing.');

            const requestLimit = 10;
            let requestCount = 0;

            for (const word of words) {
                if (word.length <= 2) continue;

                const { cities } = await getCities(token, word);
                requestCount++;

                if (cities?.length) {
                    setParsedCityName(cities[0].city);
                    break;
                }

                if (requestCount >= requestLimit) break;
            }
        } catch (error) {
            console.error("Error fetching city:", error.message || error);
        }
    };

    const parseClientInfo = (text) => {
        if (!text || !parserData) return;

        const textArr = text.trim().split(/[,\s\n]+/);
        const allParsedInfo = { ...parsedValues };
        const newValues = {};
        let cuttedText;
        const middleNameRe = /(ович|івна|ївна|ич|іч)/i;
        const phoneRe = /(?:\+?38)?(0\d{2}\d{7})/;
        const cityRe = /(?:місто |м\. ?|м\.)([а-яґєії]+)/i;
        const notCityRe = /(область|обл\.?|[а-яґєії]+ська|телефон|тел\.?|№? ?\d+|відд?іленн?я|нова пошта|нп\.?|вулиця|вул\.?|поштомат)/gi;

        const parsedPhone = text.replace(/[-\s\n()]/g, "").match(phoneRe);
        allParsedInfo.phone = parsedPhone?.length && parsedPhone[1] ? "+38" + parsedPhone[1] : "";
        cuttedText = text.replace(phoneRe, "");

        for (let i = 0; i < textArr.length; i++) {
            if (parserData?.firstNames && (textArr[i].toLowerCase() in parserData.firstNames)) {
                allParsedInfo.first_name = textArr[i];

                if (i > 0) {
                    allParsedInfo.last_name = textArr[i - 1];
                }
                if ((i + 1 !== textArr.length) && !allParsedInfo.middle_name) {
                    const isMiddleName = middleNameRe.test(textArr[i + 1]);
                    if (isMiddleName) {
                        allParsedInfo.middle_name = textArr[i + 1];
                    }
                }
            }
        }
        cuttedText = cuttedText
            .replace(allParsedInfo.first_name, "")
            .replace(allParsedInfo.last_name, "")
            .replace(allParsedInfo.middle_name, "");

        if ('city' in parsedValues) {
            const parsedCity = cuttedText.match(cityRe);
            allParsedInfo.city = parsedCity && parsedCity[1] ? parsedCity[1] : "";
            cuttedText = cuttedText.replace(cityRe, "");

            if (!allParsedInfo.city) {
                cuttedText = cuttedText
                    .replace(notCityRe, "")
                    .replace(/,/g, "")
                    .replace(/(\s{2,}|\n)/g, " ")
                    .trim();

                const words = cuttedText.split(" ");
                fetchCity(words);
            }
        }

        for (const k in parsedValues) {
            newValues[k] = allParsedInfo[k];
        }

        const { 'city': _, ...newValuesToUpdate } = newValues;

        setValues(newValues);
        updateData(newValuesToUpdate);
        setIsParsed(true);
        setIsEdit(false);
    }

    const onTextareaChange = (e) => {
        setClientInfo(e.target.value);

        if (!e.target.value) {
            setIsParsed(false);
            setValues(initialFormValues);
            return;
        }

        if (isParsed) return;

        parseClientInfo(e.target.value);
    }

    const handleAddValue = (key) => {
        if (isEdit) return;

        if (!isAddValue) {
            setNewValue({ [key]: [] });
            setIsAddValue(true);
        } else {
            const key = Object.keys(newValue)[0];
            if (newValue[key].length) {
                newValue[key].sort((a, b) => Number(a.id) - Number(b.id));
                let value = newValue[key].map(el => el.value).join(" ");
                if (key === 'phone') {
                    value = value.replace(/[-\s()]/g, "");
                    const idx = value.indexOf('0');
                    if (idx !== -1) {
                        value = '+38' + value.slice(idx);
                    }
                }
                setValues({ ...parsedValues, [key]: value });
                updateData({ ...parsedValues, [key]: value });
            }
            setIsAddValue(false);
            setNewValue({});
        }
    }

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            setIsParsed(false);
            setClientInfo(text);
            parseClientInfo(text);
        } catch (err) {
            console.error('Failed to read clipboard: ', err);
        }
    }

    const handleApplyEdit = () => {
        if (!clientInfo) return;
        setIsEdit(false);
        parseClientInfo(clientInfo);
    }

    const handleAddWord = (e) => {
        const { value, checked, name } = e.target;
        const updatedNewValue = { ...newValue };
        const valueKey = Object.keys(updatedNewValue)[0];

        if (checked) {
            updatedNewValue[valueKey].push({ id: name, value });
        } else {
            const idx = updatedNewValue[valueKey].findIndex(el => el.id === name);
            updatedNewValue[valueKey].splice(idx, 1);
        }

        setNewValue(updatedNewValue);
    }

    return (
        <div className={styles.parser} style={parserStyles}>
            <div className={styles.parserHead} onClick={() => setIsParserOpened(!isParserOpened)}>
                <h3>Парсер</h3>
                <img src={arrowDown} className={styles.arrowImg} alt="Arrow down" />
            </div>
            {isParserOpened && (
                <div className={`${styles.parserBody} ${isParserOpened ? styles.parserOpened : ""}`}>
                    <div className={styles.parsedValues}>
                        {Object.entries(parsedValues).map(([key, value]) => (
                            value ? (
                                <div
                                    key={key}
                                    className={`${styles.parsedValue} ${styles.label} ${styles[key]}`}
                                >
                                    <span>{value}</span>
                                    <button
                                        onClick={() => {
                                            setValues({ ...parsedValues, [key]: "" })
                                            updateData({ [key]: "" })
                                        }}
                                    >
                                        <img src={closeCross} alt="Close cross" />
                                    </button>
                                </div>
                            ) : (
                                <div
                                    key={key}
                                    className={`${styles.parsedValue} ${isEdit || (isAddValue && !newValue[Object.keys(newValue)[0]].length) ? styles.disabled : ""} ${styles.label} ${styles[key]}`}
                                    onClick={() => handleAddValue(key)}
                                >
                                    <span>{key}</span>
                                    <span className={styles.plusSign}><img src={plusIcon} alt="Plus sign" /></span>
                                </div>
                            )
                        ))}
                    </div>
                    <div className={styles.textarea}>
                        {!clientInfo || isEdit ? (
                            <>
                                <textarea
                                    name="clientOrderText"
                                    id="clientOrderText"
                                    onChange={onTextareaChange}
                                    value={clientInfo}
                                />
                                <button className={styles.pasteEditBtn} onClick={handlePaste}>
                                    <img src={paste} alt="" />
                                </button>
                                <button className={styles.applyBtn} onClick={handleApplyEdit}>
                                    <img src={applyIcon} alt="" />
                                </button>
                            </>
                        ) : (
                            <>
                                <div className={styles.wordButtons}>
                                    {clientInfo.trim().split(/[,\s\n]+/).map((word, idx) => (
                                        <label
                                            key={idx}
                                            className={`${styles.wordButton} ${isAddValue ? styles.label : ""}`}
                                        >
                                            {word}
                                            <input
                                                type="checkbox"
                                                name={idx}
                                                value={word}
                                                checked={!!newValue[Object.keys(newValue)[0]]?.find(el => el.id === String(idx))}
                                                onChange={handleAddWord}
                                                disabled={!isAddValue}
                                            />
                                        </label>
                                    ))}
                                </div>
                                <button className={styles.pasteEditBtn} onClick={() => setIsEdit(true)}>
                                    <img src={editIcon} alt="" />
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Parser;