import React from "react";
import styles from './FabricSlider.module.scss';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from "../../assets/icons/copy4.svg";

const FabricSlider = ({images}) => {

    const settings = {
        customPaging: function(i) {
            return (
                <a>
                    <img src={images[i]} alt=""/>
                </a>
            );
        },
        dots: true,
        arrows: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className={styles.fabricSlider}>
            <Slider {...settings}>
                {
                    images.map((img, index) => (
                        <div className={styles.sliderItem} key={index}>
                            <img className={styles.sliderImg} src={img} alt=""/>
                            <CopyToClipboard text={'COPY'}>
                                <button>
                                    <img src={copyIcon} alt=""/>
                                </button>
                            </CopyToClipboard>
                        </div>
                    ))
                }
            </Slider>
        </div>
    )
}

export default FabricSlider;