import styles from "./AddComponentButton.module.scss";
import plusSign from "../../../../assets/icons/plus.svg";

export default function AddComponentButton({ onClick }) {
    return (
        <button className={styles.componentButton} onClick={onClick}>
            <img src={plusSign} className={styles.plus} alt="Add component" />
        </button>
    )
}
