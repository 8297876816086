import React from "react";
import styles from './Preloader.scss';

const Preloader = () => {
    return (
        <div className='preloaderWrapper'>
            <div className="preloader">
                <hr/>
                <hr/>
                <hr/>
                <hr/>
            </div>
        </div>

    )
}

export default Preloader;