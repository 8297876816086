import React, {useRef, useState} from "react";
import styles from './FabricSize.module.scss';
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import GlobalCalculator from "../../../Common/GlobalCalculator/GlobalCalculator";
import {useAppDispatch} from "../../../../hooks/redux";
import {setIsActivePopup} from "../../../../store/main-slice";

const FabricSize = ({kitTemplateSizes, setKitSize, setCustomTemplateSize, isEdit}) => {
    const [activeSize, setActiveSize] = useState(() =>
        isEdit ? {index: null, standard: 1} : {index: null, standard: 0}
    );
    const dispatch = useAppDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');
    const [currentFocus, setCurrentFocus] = useState(null);
    const [lastEditedInput, setLastEditedInput] = useState('');
    const widthInputRef = useRef(null);
    const lengthInputRef = useRef(null);

    const handleSizeClick = (sizeIndex, size) => {
        if (!isEdit) {
            if (activeSize.index !== sizeIndex) {
                setActiveSize({standard: 1, index: sizeIndex});
                setKitSize(size);
                setCustomTemplateSize('');
            } else if (activeSize.index === sizeIndex && activeSize.standard === 1) {
                setWidth('');
                setLength('');
                onShowPopup();
            } else {
                onShowPopup();
            }
        } else {
            onShowPopup();
        }

    };

    const onSizeSave = () => {
        if (!isEdit) {
            setActiveSize({standard: 0, index: activeSize.index});
            setCustomTemplateSize(`${width}x${length}`);
        } else
            //fix edit saving
        {
            setActiveSize({index: null, standard: 0});
            if (width && length) {
                setCustomTemplateSize(`${width}x${length}`);
            } else {
                setCustomTemplateSize('');
                setActiveSize({index: null, standard: 1});
            }
        }
        onClosePopup();
    }

    const onShowPopup = () => {
        dispatch(setIsActivePopup(true));
        setShowPopup(true);
    }

    const onClosePopup = () => {
        dispatch(setIsActivePopup(false));
        setShowPopup(false);
    }

    const handleWidthChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = width + value;
        if (width === '0') {
            potentialNewValue = value;
        }
        if (potentialNewValue.length <= 3 && /^\d{1,3}$/.test(potentialNewValue)) {
            let numericValue = parseInt(potentialNewValue, 10);
            if (numericValue <= 999) {
                setWidth(potentialNewValue);
                setLastEditedInput('width');
                if (potentialNewValue.length === 3 || numericValue === 0) {
                    lengthInputRef.current.focus();
                }
            }
        }
    }

    const handleWidthDelete = () => {
        let newValue = width.slice(0, width.length - 1);
        setWidth(newValue);
    }

    const handleLengthChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = length + value;
        if (potentialNewValue.length <= 3 && /^\d{1,3}$/.test(potentialNewValue)) {
            let numericValue = parseInt(potentialNewValue, 10);
            if (numericValue <= 999) {
                setLength(potentialNewValue);
                setLastEditedInput('length');
            }
        }
    }

    const handleLengthDelete = () => {
        let newValue = length.slice(0, length.length - 1);
        setLength(newValue);
    }

    const handleChange = (e) => {
        if ((currentFocus === 'width' && width.length !== 3) || (!currentFocus && width.length !== 3)) {
            handleWidthChange(e)
        } else if (currentFocus === 'length' || (!currentFocus && width.length === 3) || (currentFocus === 'width' && width.length === 3)) {
            handleLengthChange(e);
        }
    }

    const handleDelete = () => {
        if (currentFocus === 'width' || (currentFocus === 'length' && length.length === 0)) {
            handleWidthDelete();
            widthInputRef.current.focus();
        } else if (currentFocus === 'length' && length.length !== 0) {
            handleLengthDelete();
        } else if (!currentFocus) {
            if (lastEditedInput === 'width') {
                handleWidthDelete();
            } else if (lastEditedInput === 'length' && length.length !== 0) {
                handleLengthDelete();
            } else if (lastEditedInput === 'length' && length.length === 0) {
                handleWidthDelete();
            }
        }
    }

    return (
        <div className={styles.size}>
            <div className={styles.size__row}>
                {!isEdit ? Array.isArray(kitTemplateSizes) && kitTemplateSizes.map((el, index) => (
                    <div key={index}
                         className={`${styles.item} ${activeSize.index === index && activeSize.standard ? styles.activeStandard : ""} ${activeSize.index === index && !activeSize.standard ? styles.activeNotStandard : ""}`}
                         onClick={() => handleSizeClick(index, el)}>
                        <div className={styles.item__panel}>
                            <span>{el.short_name}</span>
                        </div>
                        <p>{el.name}</p>
                    </div>
                )) : Array.isArray(kitTemplateSizes) && kitTemplateSizes.filter(el => el.selected === true).length > 0
                    ? kitTemplateSizes
                        .filter(el => el.selected === true)
                        .map((el, index) => (
                            <div key={index} className={`${styles.item} 
                            ${activeSize.standard === 1 ? styles.activeStandard : ""} 
                            ${activeSize.standard === 0 ? styles.activeNotStandard : ""}`}
                                 onClick={handleSizeClick}>
                                <div className={styles.item__panel}>
                                    <span>{el.short_name}</span>
                                </div>
                                <p>{el.name}</p>
                            </div>
                        ))
                    : <p>No selected sizes available</p>
                }
            </div>
            <div className={styles.size__descWrap}>
                <div className={styles.desc}>
                    <span className={styles.desc__color}></span>
                    <p>Стандарт</p>
                </div>
                <div className={styles.desc}>
                    <span className={`${styles.desc__color} ${styles.desc__notStandard}`}></span>
                    <p>Не стандарт</p>
                </div>
            </div>
            {showPopup && (
                <GlobalPopup title={'Розмір ковдри'} onClose={() => onClosePopup()}>
                    <GlobalCalculator handleChange={handleChange} handleDelete={handleDelete}
                                      onSubmit={() => onSizeSave()}>
                        <div className={styles.sizeInputWrap}>
                            <input id='kit_width' name="kit_width" value={width}
                                   className={'baseInput'} type="text" readOnly onFocus={() => setCurrentFocus('width')}
                                   ref={widthInputRef}/>
                            <span>х</span>
                            <input id='kit_length' name="kit_length" value={length}
                                   className={'baseInput'} type="text" readOnly
                                   onFocus={() => setCurrentFocus('length')} ref={lengthInputRef}/>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
        </div>
    )
}

export default FabricSize