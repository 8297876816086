import React from "react";
import styles from './CancelOrder.module.scss';
import Button from './Button/Button';

const CancelOrder = ({onConfirm, onCancel}) => {
    return (
        <div className={styles.cancelOrder}>
            <h2>Ви бажаєте<br/>видалити замовлення?</h2>
            <Button confirm action={onConfirm}/>
            <Button action={onCancel}/>
        </div>
    )
}

export default CancelOrder;