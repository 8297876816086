import React from "react";
import styles from './Order.module.scss';


const PayCharger = ({payType, setPayType, auditDates}) => {
    const onChangePay = (value) => {
        setPayType(value);
        auditDates(value, 'type', 'payment')
    }

    return (
        <div className={styles.payChanger}>
            <span>Тип оплати</span>
            <div className={styles.payChanger__wrapper}>
                <div className={'charger'}>
                    <span>Онлайн</span>
                    <input type="radio" name='payCharger' checked={payType === 'ONLINE'}
                           onChange={() => onChangePay('ONLINE')} />
                    <p>Онлайн</p>
                </div>
                <div className={'charger'}>
                    <span>на Р/Р</span>
                    <input type="radio" name='payCharger'  checked={payType === 'REQ'}
                           onChange={() => onChangePay('REQ')} />
                    <p>на Р/Р</p>
                </div>
                <div className={'charger'}>
                    <span>Післяоплата</span>
                    <input type="radio" name='payCharger'  checked={payType === 'POSTPAID'}
                           onChange={() => onChangePay('POSTPAID')} />
                    <p>Післяоплата</p>
                </div>
            </div>
        </div>
    )
}

export default PayCharger;