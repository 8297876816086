import React, {useEffect, useMemo, useState} from "react";
import styles from './OrderStatus.module.scss';
import OrderInfoBlock from "../../Common/OrderInfoBlock/OrderInfoBlock";
import Img from "../../../assets/img/slide.png";
import ProductItem from "../../Common/ProductItem/ProductItem";
import OrderActions from "./OrderActions";
import {getAccessToken, getCartOrder, getDeliverNP, getOrderInfo} from "../../../api/api";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getIsLoading, getLastOrder} from "../../../store/selectors";
import PriceInfo from "../../ClientPage/Status/Price/Price";
import {setIsLoading, setTotalPrice} from "../../../store/main-slice";
import Preloader from "../../Common/Preloader/Preloader";

const OrderStatus = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getIsLoading);
    const lastOrderId = useAppSelector(getLastOrder);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const invoice = 'ТТН 89343473647382487';

    const getOrderStatusName = (orderStatus) => {
        switch (orderStatus) {
            case 'PAID': return 'Оплачено';
            case 'IN_PROG': return 'В обробці';
            case 'PAY_WAIT': return 'Очікує оплати'
            case 'NOT_PAID': return 'Не оплачено';
            default:
                console.log(orderStatus)
                break;
        }
        return orderStatus
    }

    const totalPrice = useMemo(() => {
        if (warehouseItems.length > 0) {
            return warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
        }
    }, [warehouseItems])

    useEffect(() => {
        getAccessToken().then((token) => {
            dispatch(setIsLoading(true));
            getCartOrder(token, lastOrderId).then((response) => {
                if (response && response.length > 0) {
                    setWarehouseItems(response);
                    dispatch(setIsLoading(false));
                }
                getOrderInfo(token, lastOrderId).then((response) => {
                    if (response && response.id) {
                        setOrder(response);
                        dispatch(setIsLoading(false));
                    }
                })
            })
            getDeliverNP(token, lastOrderId).then((result) => {
                if (result && result.length > 0) {
                    setDeliveryInfo(result[0])
                }
            })
        });
    }, [])

    useEffect(() => {
        if (totalPrice) {
            dispatch(setTotalPrice(totalPrice));
        }
    }, [totalPrice])

    return (
        <div className={styles.orderStatus}>
            {order && order.id && (
                <h2>Замовлення № {order.id}</h2>
            )}
            {order && (
                <OrderInfoBlock invoice={invoice} order={order} deliveryInfo={deliveryInfo}/>
            )}
            {order?.payment?.status && order?.payment?.status !== 'NONE' && (
                <div className={styles.statusBar}>
                    <span>{getOrderStatusName(order?.payment?.status)}</span>
                </div>
            )}
            {warehouseItems && warehouseItems.length > 0 && warehouseItems.map((el, index) => <ProductItem
                item={el} key={index}/>)}
            <OrderActions/>
            <PriceInfo order={order} totalPrice={totalPrice}/>
            <br/>
            <br/>
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}

export default OrderStatus;