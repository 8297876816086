import './App.scss';
import React, {useEffect, useMemo, useState} from "react";
import {getIsAuth} from "./store/selectors";
import {useAppDispatch, useAppSelector} from "./hooks/redux";
import ClientPage from "./components/ClientPage/ClientPage";
import Shadow from "./components/Common/Shadow/Shadow";
import AuthorizedPage from "./components/AuthorizedPage/AuthorizedPage";
import {getAccessToken, getUserInfo, sendOrderHash} from "./api/api";
import {setAuth, setUserInfo} from "./store/account-slice";
import queryString from "query-string";
import {useLocation, useNavigate} from "react-router";

function App() {
    const isAuth = useAppSelector(getIsAuth);
    const dispatch = useAppDispatch();
    const [accessToken, setAccessToken] = useState(null)
    const navigate = useNavigate();
    const params = useLocation();
    const urlString = params.search;
    const hash = queryString.parse(urlString);
    const [order, setOrder] = useState(null);

    useEffect(() => {
        if (urlString && hash?.order) {
            sendOrderHash(hash?.order).then((response) => {
                if (response?.order) {
                    setOrder(response)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (order) {
            navigate(`/order/${order?.order?.id}`);
        }
    }, [order])

    getAccessToken().then((token) => {
        if (!accessToken && token && token.length > 1) {
            setAccessToken(token)
        }
    })

    useEffect(() => {
        if (accessToken) {
            getUserInfo(accessToken).then((result) => {
                if (result && result.id) {
                    dispatch(setUserInfo(result));
                    dispatch(setAuth(true));
                } else {
                    dispatch(setAuth(false));
                }
            })
        }
    }, [accessToken])


    return (
        <div className='wrapper'>
            {isAuth
                ? <AuthorizedPage/>
                : <ClientPage/>
            }
            <Shadow/>
        </div>
    );
}

export default App;