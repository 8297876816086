import React, {useEffect, useState} from "react";
import styles from '../../FabricSet.module.scss';

const FabricSetItemType = ({item, types, setContainerPosition}) => {
    const [chosenTypes, setChosenTypes] = useState([]);
    const [nextThirdStep, setNextThirdStep] = useState(false);

    const handleCircleClick = (circleIndex) => {
        setContainerPosition(2);
        if (types && types.length === 2) {
            //first click after render
            if (chosenTypes.length === 2 && !nextThirdStep) {
                setChosenTypes(['A']);
            }
            //1
            if (chosenTypes.length === 1 && chosenTypes.includes('A')) {
                setNextThirdStep(true);
                setChosenTypes(['A', 'B']);
            }
            //2
            if (chosenTypes.length === 2 && nextThirdStep) {
                setChosenTypes(['B']);
                setNextThirdStep(false);
            }
            //3
            if (chosenTypes.length === 1 && chosenTypes.includes('B')) {
                setChosenTypes(['A']);
            }
        }
    };

    useEffect(() => {
        setChosenTypes([...(types || [])]);
    }, [item]);

    return (
        <div className={styles.circleContainer} onClick={() => handleCircleClick()}>
            <div
                className={`${styles.circle} ${chosenTypes.length > 0 && chosenTypes.includes('A') ? styles.active : ""}`}>
                <span>A</span>
            </div>
            <div
                className={`${styles.circle} ${chosenTypes.length > 0 && chosenTypes.includes('B') ? styles.active : ""}`}>
                <span>B</span>
            </div>
        </div>
    )
}

export default FabricSetItemType