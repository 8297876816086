import React, {useEffect, useMemo, useState} from "react";
import styles from './Products.module.scss';
import ProductActions from "./ProductActions/ProductActions";
import DiscountField from "../OrderCreate/DiscountField";
import {getAccessToken, getCartOrder, getOrderInfo, updatePayment} from "../../../api/api";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getIsLoading, getLastOrder} from "../../../store/selectors";
import CartProducts from "./CartProducts/CartProducts";
import TotalPrice from "./TotalPrice/TotalPrice";
import Preloader from "../../Common/Preloader/Preloader";
import {setIsLoading, setTotalPrice} from "../../../store/main-slice";

const Products = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getIsLoading);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([]);
    const [discountType, setDiscountType] = useState('');
    const [percentInputValue, setPercentInputValue] = useState(0);
    const [UAHInputValue, setUAHInputValue] = useState(0);
    const [popup, setPopup] = useState(null);
    const lastOrderId = useAppSelector(getLastOrder);

    const totalPrice = useMemo(() => {
        if (warehouseItems.length > 0) {
            return warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
        }
    }, [warehouseItems])

    useEffect(() => {
        getAccessToken().then((token) => {
            dispatch(setIsLoading(true));
            getCartOrder(token, lastOrderId).then((response) => {
                if (response && response.length > 0) {
                    setWarehouseItems(response);
                    dispatch(setIsLoading(false));
                }
                getOrderInfo(token, lastOrderId).then((response) => {
                    if (response && response.id) {
                        setOrder(response);
                        dispatch(setIsLoading(false));
                    }
                })
            })
        });
    }, [])

    useEffect(() => {
        if (totalPrice) {
            dispatch(setTotalPrice(totalPrice));
        }
    }, [totalPrice])

    useEffect(() => {
        if (order?.payment) {
            const {discount_type, discount_amount} = order.payment;
            if (discountType !== discount_type) {
                setDiscountType(discount_type);
                if (discount_type === 'VAL' && discount_amount) {
                    setUAHInputValue(discount_amount)
                } else if (discount_type === 'PERC' && discount_amount) {
                    setPercentInputValue(discount_amount);
                }
            }
        }
    }, [order])

    return (
        <div className={styles.products}>
            <h2 className={styles.products__title}>Товар</h2>
            {warehouseItems && (
                <CartProducts items={warehouseItems} setItems={setWarehouseItems}/>
            )}
            <ProductActions order={order} popup={popup} setPopup={setPopup} setOrder={setWarehouseItems}/>
            <TotalPrice
                price={totalPrice || order?.payment?.amount}
                discountType={discountType}
                discountValue={discountType === 'PERC' ? percentInputValue : UAHInputValue}
            />
            <DiscountField
                percentInputValue={percentInputValue}
                setPercentInputValue={setPercentInputValue}
                UAHInputValue={UAHInputValue}
                setUAHInputValue={setUAHInputValue}
                discountType={discountType}
                setDiscountType={setDiscountType}
                popup={popup}
                setPopup={setPopup}
                orderId={order?.id}
                getAccessToken={getAccessToken}
                updatePayment={updatePayment}
            />
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}

export default Products;