import React from "react";
import styles from './InvoiceInfo.module.scss';
import SearchInput from "./Input/SearchInput";


const InvoiceInfo = ({order}) => {
    return (
        <div className={styles.invoiceInfo}>
            <div className={styles.invoiceInfo__status}>
                <p>Очікує відправки</p>
            </div>
            <div className={styles.invoiceInfo__search}>
                <SearchInput order={order} />
            </div>
        </div>
    )
}

export default InvoiceInfo;