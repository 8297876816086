import React, {useEffect, useState} from "react";
import styles from './Price.module.scss'

const PriceInfo = ({order, totalPrice}) => {
    const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState(0);
    const price = totalPrice || order?.payment?.amount

    const calculateTotalSum = (price, discountValue, discountType) => {
        switch (discountType) {
            case 'PERC': return Math.ceil(price - ((price * discountValue) / 100))
            case 'VAL': return price - discountValue
            default:
                break;
        }
        return discountValue;
    }

    useEffect(() => {
        if (order?.payment) {
            const { discount_amount, discount_type } = order.payment;
            let result = calculateTotalSum(price, discount_amount, discount_type);
            setTotalPriceWithDiscount(result);
        }
    }, [order]);

    return (
        <div className={styles.price}>
            {price > totalPriceWithDiscount && (
                <p className={styles.oldPrice}>{price} грн</p>
            )}
            <div className={styles.price__info}>
                <p>Ціна замовлення:</p>
                <span className={styles.price__number}>{totalPriceWithDiscount} грн</span>
            </div>
            {order?.payment?.discount_amount > 0 && (
                <div className={styles.discount}>
                    <>
                        <p>Знижка: {order.payment.discount_type === 'PERC' && <span className={styles.discount__percent}>{order.payment.discount_amount}%</span>}</p>
                        <span className={styles.discount__number}>
                            -{order.payment.discount_type === 'VAL'
                                ? order.payment.discount_amount
                                : Math.floor((price * order.payment.discount_amount) / 100)} грн
                        </span>
                    </>
                </div>
            )}
        </div>
    )
}

export default PriceInfo;