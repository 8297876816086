import React, {useState} from "react";
import styles from './AuthForm.module.scss';
import {login} from "../../../../api/api";
import {useAppDispatch} from "../../../../hooks/redux";
import {setAuth} from "../../../../store/account-slice";
import {setIsActivePopup} from "../../../../store/main-slice";

const AuthForm = () => {
    const [authLogin, setAuthLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const dispatch = useAppDispatch();

    const submitForm = () => {
        if (!authLogin) {
            setErrors(prevErrors => ({...prevErrors, login: 'Required'}));
        }
        if (!password) {
            setErrors(prevErrors => ({...prevErrors, password: 'Required'}));
        }
        if (authLogin && password) {
            setIsLoading(true);
            login(authLogin, password).then((result) => {
                if (result && result.access) {
                    dispatch(setAuth(true));
                    dispatch(setIsActivePopup(false))
                    setErrors(null);
                } else if(result.detail) {
                    setErrors({ login: result.detail, password: result.detail });
                }
                setIsLoading(false);
            });
        }
    }

    return (
        <div className={styles.authForm}>
            <div className={styles.form}>
                <div className={styles.form__field}>
                    <label htmlFor="login">Логін:</label>
                    <input id={'login'} name={'login'} value={authLogin} className={`baseInput ${errors?.login ? 'baseInputError' : ''}`} type="text"
                           onChange={(e) => setAuthLogin(e.target.value)} onFocus={() => setErrors(prevErrors => ({...prevErrors, login: null}))}/>
                </div>
                <div className={styles.form__field}>
                    <label htmlFor="password">Пароль:</label>
                    <input id={'password'} name={'password'} value={password} className={`baseInput ${errors?.password ? 'baseInputError' : ''}`} type="password"
                           onChange={(e) => setPassword(e.target.value)} onFocus={() => setErrors(prevErrors => ({...prevErrors, password: null}))}/>

                </div>
            </div>
            <button className={`${styles.action} globalBtn`} disabled={isLoading} onClick={submitForm}>
                <span>Увійти</span>
            </button>
        </div>
    )
}

export default AuthForm;