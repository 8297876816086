import React, {useEffect, useState} from "react";
import styles from './CallbackForm.module.scss';
import {setIsActivePopup} from "../../../../store/main-slice";
import {useAppDispatch} from "../../../../hooks/redux";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import PopupCheckboxes from "../../../Common/PopupCheckboxes/PopupCheckboxes";
import {getReason, setCallOrder} from "../../../../api/api";


const CallbackForm = () => {
    const dispatch = useAppDispatch();
    const [chooseList, setChooseList] = useState(null)
    const [reason, setReason] = useState([]);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('+380');

    useEffect(() => {
        if (chooseList === null) {
            getReason().then((result) => {
                if (result && result.length > 0) {
                    const newArray = result.map((item) => {
                        return {title: item.name, value: item.id}
                    })
                    setChooseList(newArray);
                }
            });
        }
    }, [])

    const onSubmit = () => {
        if (name.length > 2 && phone.length > 7 && reason.length > 0) {
            const arrayForSend = reason.map((item) => {
                return item.value
            })

            setCallOrder(name, phone, arrayForSend).then((res) => console.log(res))
        }
    }

    return (
        <div className={styles.CallbackForm}>
            <input type="text" placeholder={'Ім’я'} value={name} onChange={(e) => setName(e.target.value)}/>
            <input type="phone" placeholder={'Номер телефону'} value={phone}
                   onChange={(e) => setPhone(e.target.value)}/>
            <button className={styles.buttonField} onClick={() => dispatch(setIsActivePopup(true))}
                    type='button'>
                <span>{'Звернення у зв\'язку з'}</span>
            </button>
            <button onClick={() => onSubmit()} type='submit' className={styles.action}
                    disabled={!name.length || !phone.length || !reason.length }>
                <span>Замовии дзвінок</span>
            </button>
            <GlobalPopup title={'Причина звернення'}>
                {chooseList && chooseList.length > 0 && (
                    <PopupCheckboxes values={reason} setValues={setReason} chooseList={chooseList} />
                )}
            </GlobalPopup>
        </div>
    )
}

export default CallbackForm;