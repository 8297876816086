import React from "react";
import styles from '../Payment.module.scss';
import PaymentGroup from '../../../../../assets/icons/wayforpayLogo.png';
import {sendPayWayForPay} from "../../../../../api/api";


const Button = ({payment}) => {

    const onSend = () => {
        if (payment?.id) (
            sendPayWayForPay(payment.id).then((response) => {
                if (response && response.invoice_url) {
                    window.location.href = response.invoice_url;
                }
            })
        )
    }

    return (
       <button className={styles.paymentBtn} onClick={() => onSend()}>
           <img src={PaymentGroup} alt=""/>
       </button>
    )
}

export default Button;