import React from "react";
import ProductItem from "../../../Common/ProductItem/ProductItem";
import { getAccessToken, removeProduct } from "../../../../api/api";

const CartProducts = ({items, setItems}) => {
    const handleDeleteItem = (id) => {
        getAccessToken().then((token) => {
            removeProduct(token, id).then(result => {
                if (result === 204) {
                    const newItems = items.filter(item => item.id !== id);
                    setItems(newItems);
                }
            })
        })
    }

    return (
        <div>
            {items && items.length > 0 && items.map((el, index) => <ProductItem item={el} key={index} onDelete={handleDeleteItem}/>)}
        </div>
    )
}

export default CartProducts;