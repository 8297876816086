import { useEffect, useState } from "react";
import ComponentsSearch from "./ComponentsSearch";
import { Pagination } from "@mui/material";
import { getAccessToken, getKitCustomComponents } from "../../../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { setIsLoading } from "../../../../../store/main-slice";
import { getIsLoading } from "../../../../../store/selectors";
import Preloader from "../../../../Common/Preloader/Preloader";
import styles from "./ComponentsPopup.module.scss";
import ComponentsFilters from "./ComponentsFilters";
import ComponentsList from "./ComponentsList";

export default function ComponentPopup({ addCustomComponent }) {
    const [searchValue, setSearchValue] = useState('');
    const [selectedFiltersIds, setSelectedFiltersIds] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [allFilters, setAllFilters] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const isLoading = useAppSelector(getIsLoading);
    const [components, setComponents] = useState([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!allFilters.length) {
            getAccessToken().then((token) => {
                dispatch(setIsLoading(true));
                getKitCustomComponents(token, 1).then((response) => {
                    if (response?.custom_components && response.custom_components.length > 0) {
                        setComponents(response.custom_components);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages)
                    }
                    if (response?.component_types) {
                        setAllFilters(response.component_types);
                    }
                    dispatch(setIsLoading(false));
                })
            }).catch(err => {
                console.error("Error fetching fabrics: ", err);
                dispatch(setIsLoading(false));
            });
        }
    }, []);

    const handlePaginationChange = (event, value) => {
        setPage(value);
        window.scrollTo({top: 0, behavior: 'auto'});
        getAccessToken().then((token) => {
            dispatch(setIsLoading(true));
            // if (filter && filter.length > 0 && searchValue?.length > 0) {
            //     getFabrics(token, value, filter, searchValue).then((response) => {
            //         if (response?.fabrics) {
            //             dispatch(setFabricsList(response.fabrics));
            //             dispatch(setIsLoading(false));
            //         }
            //         if (response?.total_pages) {
            //             setTotalPages(response.total_pages)
            //         }
            //     }).catch(err => {
            //         console.error("Error fetching fabrics on page change: ", err);
            //         dispatch(setIsLoading(false));
            //     })
            // } else if (filter && filter.length > 0 && searchValue?.length === 0) {
            //     getFabrics(token, value, filter).then((response) => {
            //         if (response?.fabrics) {
            //             dispatch(setFabricsList(response.fabrics));
            //             dispatch(setIsLoading(false));
            //         }
            //         if (response?.total_pages) {
            //             setTotalPages(response.total_pages)
            //         }
            //     }).catch(err => {
            //         console.error("Error fetching fabrics on page change: ", err);
            //         dispatch(setIsLoading(false));
            //     })
            // } else {
            //     getFabrics(token, value).then((response) => {
            //         if (response?.fabrics) {
            //             dispatch(setFabricsList(response.fabrics));
            //             dispatch(setIsLoading(false));
            //         }
            //         if (response?.total_pages) {
            //             setTotalPages(response.total_pages)
            //         }
            //     }).catch(err => {
            //         console.error("Error fetching fabrics on page change: ", err);
            //         dispatch(setIsLoading(false));
            //     })
            // }
        })
    };

    const onSendFilters = (filterIds) => {
        setTimeout(() => {
            getAccessToken().then((token) => {
                dispatch(setIsLoading(true));
                if (!searchValue.length) {
                    getKitCustomComponents(token, 1, filterIds).then((response) => {
                        if (response?.custom_components) {
                            setComponents(response.custom_components);
                        }
                        if (response?.total_pages) {
                            setTotalPages(response.total_pages)
                        }
                        dispatch(setIsLoading(false));
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        dispatch(setIsLoading(false));
                    })
                } else {
                    getKitCustomComponents(token, 1, filterIds, searchValue).then((response) => {
                        if (response?.custom_components) {
                            setComponents(response.custom_components);;
                        }
                        if (response?.total_pages) {
                            setTotalPages(response.total_pages)
                        }
                        dispatch(setIsLoading(false));
                    }).catch(err => {
                        console.error("Error fetching fabrics on page change: ", err);
                        dispatch(setIsLoading(false));
                    })
                }
            })
        },)
    }

    const handleSelectFilter = (value) => {
        if (selectedFilters.length) {
            setComponents([]);
            const isItemValue = selectedFilters.find((item) => item === value);
            if (isItemValue) {
                const newArray = selectedFilters.filter((item) => item !== value);
                const newFilters = selectedFiltersIds.filter((item) => item !== value.id);
                setSelectedFilters([...newArray]);
                setSelectedFiltersIds([...newFilters]);
                onSendFilters([...newFilters])

            } else {
                setSelectedFilters([...selectedFilters, value]);
                setSelectedFiltersIds([...selectedFiltersIds, value.id]);
                onSendFilters([...selectedFiltersIds, value.id]);
            }
        } else {
            const newArray = [value];
            setSelectedFilters(newArray);
            setSelectedFiltersIds([value.id]);
            onSendFilters([value.id]);
        }
    }

    return (
        <div className={styles.components}>
            <ComponentsSearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                selectedFiltersIds={selectedFiltersIds}
                setPage={setPage}
                setTotalPages={setTotalPages}
                setComponents={setComponents}
            />
            {allFilters && allFilters.length > 0 && (
                <ComponentsFilters
                    allFilters={allFilters}
                    selectedFilters={selectedFilters}
                    handleSelectFilter={handleSelectFilter}
                />
            )}
            {Array.isArray(components) && components.length > 0 ? (
                <ComponentsList components={components} onAddComponent={addCustomComponent} />
            ) : (
                <p className={styles.warning}>Очікуємо доступні комплектуючі</p>
            )}
            <Pagination count={totalPages} page={page} siblingCount={1} boundaryCount={1} hidePrevButton hideNextButton onChange={handlePaginationChange} />
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}
