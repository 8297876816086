import React, {useState} from "react";
import BtnGroup from "./Button1/Button";
import Btn from "./Button2/Button";
import IbanBtn from "./Button3/Button";
import GlobalPopup from "../../../Common/GlobalPopup/GlobalPopup";
import {useAppDispatch} from "../../../../hooks/redux";
import {setIsActivePopup} from "../../../../store/main-slice";
import PaymentDone from "../../FinishOrder/PaymentDone/PaymentDone";

const Payment = ({order}) => {
    return (
        <div>
            <h3>Оплатити онлайн</h3>
            {order && order.payment && (
                <BtnGroup payment={order.payment} />
            )}
            <IbanBtn />
            {order && order.payment && (
                <Btn payment={order.payment} />
            )}
        </div>
    )
}

export default Payment;