import React, {useEffect, useMemo, useState} from "react";
import styles from './Status.module.scss';
import logo from "../../../assets/img/logoEgo.svg";
import Details from "./Details/Details";
import InvoiceInfo from "./InvoiceInfo/InvoiceInfo";
import List from "./List/List";
import Price from "./Price/Price";
import Payment from "./Payment/Payment";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import Link from "./Link/Link";
import {useLocation} from "react-router";
import queryString from 'query-string';
import {sendOrderHash} from "../../../api/api";


const Status = () => {
    const params = useLocation();
    const urlString = params.search;
    const hash = queryString.parse(urlString);
    const [order, setOrder] = useState(null);
    const [warehouseItems, setWarehouseItems] = useState([])

    const totalPrice = useMemo(() => {
        if (warehouseItems.length > 0) {
            return warehouseItems.reduce((sum, {warehouse_item, kit}) => sum + (warehouse_item ? warehouse_item.price : kit.price_retail), 0);
        }
    }, [warehouseItems])

    useEffect(() => {
        if (urlString && hash?.order) {
            sendOrderHash(hash?.order).then((response) => {
                if (response?.order) {
                    setOrder(response)
                }
                if (response?.products) {
                    const warehouse_items = response.products.map(product => ({ warehouse_item: product }));
                    const kit = response.kits.map(kit => ({ kit: kit }));
                    setWarehouseItems(() => [ ...warehouse_items, ...kit ]);
                }
            })
        }
    }, [])

    return (
        <div className={styles.status}>
            <div className={styles.status__logo}>
                <img src={logo} alt=""/>
            </div>
            {order && (
                <h2>Замовлення № {order.order.id}</h2>
            )}
            {order && (
                <p className={styles.status__date}>
                    {order.order.created.substring(8, 10)}.{order.order.created.substring(5, 7)}.{order.order.created.substring(0, 4)}
                </p>
            )}
            <Details orderId={order?.order?.id} />
            <InvoiceInfo order={order} />
            <List products={warehouseItems}/>
            <Price order={order} totalPrice={totalPrice}/>
            <Payment order={order}/>
            <PaymentInfo/>
            <Link/>
        </div>
    )
}

export default Status;