import React, {useEffect, useState} from "react";
import styles from './Storage.module.scss';
import searchIcon from "../../../assets/icons/search.svg";
import StorageItem from "../../Common/StorageItem/StorageItem";
import {getAccessToken, getStorageProducts} from "../../../api/api";
import {Pagination} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getIsLoading} from "../../../store/selectors";
import {setIsLoading} from "../../../store/main-slice";
import Preloader from "../../Common/Preloader/Preloader";
import SearchStorage from "../../Common/SearchStorage/SearchStorage";

const Storage = () => {
    const isLoading = useAppSelector(getIsLoading);
    const dispatch = useAppDispatch();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchValue, setSearchValue] = useState('');


    useEffect(() => {
        if (list && list.length === 0) {
            getAccessToken().then((token) => {
                dispatch(setIsLoading(true));
                getStorageProducts(token, 1).then((res) => {
                    if (res?.items && res.items.length > 0) {
                        setList(res.items);
                        dispatch(setIsLoading(false));
                    }
                    if (res?.total_pages) {
                        setTotalPages(res.total_pages);
                        dispatch(setIsLoading(false));
                    } else {
                        dispatch(setIsLoading(false));
                    }

                })
            })
        }
    }, [])

    const handlePaginationChange = (event, value) => {
        dispatch(setIsLoading(true));
        setPage(value);
        window.scrollTo({top: 0, behavior: 'auto'});
        getAccessToken().then((token) => {
            getStorageProducts(token, value).then((res) => {
                if (res?.items && res.items.length > 0) {
                    setList(res.items);
                    dispatch(setIsLoading(false));
                }}).catch(err => {
                dispatch(setIsLoading(false));
                console.error("Error fetching storage items on a page change", err);
            })
        })
    }

    return (
        <div className={styles.storage}>
            <div className={styles.storage__search}>
                <SearchStorage searchValue={searchValue} setSearchValue={setSearchValue} setPage={setPage}
                               setTotalPages={setTotalPages} setStorageList={setList}/>
            </div>
            {list && list.length > 0 &&
                list.map(({name, quantity, image, size, color, id}, index) => <StorageItem key={index} name={name} quantity={quantity} image={image} size={size} color={color} id={id} copy/>)
            }
            {totalPages > 1 && (
                <Pagination className={styles.pagination} count={totalPages} page={page} siblingCount={1}
                            boundaryCount={1} hidePrevButton hideNextButton onChange={handlePaginationChange}/>
            )}
            {isLoading && (
                <Preloader />
            )}
        </div>
    )
}

export default Storage;