import { useEffect } from "react";
import { getAccessToken, getKitCustomComponents } from "../../../../../api/api";
import searchIcon from "../../../../../assets/icons/search.svg";
import styles from "./ComponentsPopup.module.scss";

export default function ComponentsSearch({ searchValue, setSearchValue, selectedFiltersIds, setPage, setTotalPages, setComponents }) {
    let timeout;

    const sendSearch = (page, selectedFiltersIds, search) => {
        setTimeout(() => {
            getAccessToken().then((token) => {
                getKitCustomComponents(token, page, selectedFiltersIds, search).then((response) => {
                    if (response?.custom_components) {
                        setComponents(response.custom_components);
                    }
                    if (response?.total_pages) {
                        setTotalPages(response.total_pages);
                    }
                }).catch(err => {
                    console.error("Error fetching fabrics on page change: ", err);
                })
            })
        }, )
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                sendSearch(1, selectedFiltersIds, searchValue);
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        } else if (searchValue === '') {
            setPage(1);
            sendSearch(1, selectedFiltersIds);
        }
    }, [searchValue]);

    return (
        <div className={styles.components__search}>
            <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}/>
            <img src={searchIcon} alt=""/>
        </div>
    )
}
