import React from "react";
import styles from './Order.module.scss';
import {setIsActivePopup} from "../../../store/main-slice";
import {useAppDispatch} from "../../../hooks/redux";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import GlobalCalculator from "../../Common/GlobalCalculator/GlobalCalculator";


const DiscountField = ({
    percentInputValue,
    setPercentInputValue,
    UAHInputValue,
    setUAHInputValue,
    discountType,
    setDiscountType,
    popup,
    setPopup,
    setDiscountValue = () => {},
    orderId,
    getAccessToken,
    updatePayment
}) => {
    const dispatch = useAppDispatch();

    const onClosePopup = () => {
        setDiscountType('');
        setDiscountValue('');
        getAccessToken().then((token) => {
            updatePayment(token, orderId, {discount_type: 'NONE', discount_amount: 0}).then((res) => console.log(res))
        });
    }

    const onSubmitPopup = (type) => {
        if (type === 'PERC') {
            setDiscountValue(percentInputValue);
            getAccessToken().then((token) => {
                updatePayment(token, orderId, {discount_type: type, discount_amount: percentInputValue}).then((res) => console.log(res))
            });
        } else if (type === 'VAL') {
            setDiscountValue(UAHInputValue);
            getAccessToken().then((token) => {
                updatePayment(token, orderId, {discount_type: type, discount_amount: UAHInputValue}).then((res) => console.log(res))
            });
        }
    }

    const onChangeDiscount = (value) => {
        if (value !== discountType) {
            setDiscountType(value);
        };
        setPopup(value);
        if (value === 'PERC') {
            setDiscountValue(percentInputValue);
        } else if (value === 'VAL') {
            setDiscountValue(UAHInputValue);
        }
        setTimeout(() => {
            dispatch(setIsActivePopup(true))
        }, 30)
    }

    const handlePercentChange = (e) => {
        let value = e.target.textContent;
        let potentialNewValue = Number(String(percentInputValue) + value);
        if (potentialNewValue <= 100) {
            setPercentInputValue(potentialNewValue);
            setDiscountValue(potentialNewValue);
        }
    }

    const handlePercentDelete = () => {
        let newValue = Math.floor(percentInputValue / 10);
        setPercentInputValue(newValue);
        setDiscountValue(newValue);
    }

    const handleUAHChange = (e) => {
        let value = e.target.textContent;
        const UAHString = String(UAHInputValue);
        const newUAHValue = Number(String(UAHInputValue) + value);
        if (UAHString.length < 4) {
            setUAHInputValue(newUAHValue);
            setDiscountValue(newUAHValue);
        }
    }

    const handleUAHDelete = () => {
        let newValue = Math.floor(UAHInputValue / 10);
        setUAHInputValue(newValue);
        setDiscountValue(newValue);
    }

    return (
        <div className={styles.discountField}>
            <span>Знижка:</span>
            <div className={styles.discountField__wrapper}>
                <div className={'charger'}>
                    <span>Онлайн</span>
                    <input type="radio" name='discountType' checked={discountType === 'PERC'}
                           onClick={() => onChangeDiscount('PERC')} onChange={() => onChangeDiscount('PERC')}/>
                    <p>{percentInputValue} %</p>
                </div>
                <div className={'charger'}>
                    <span>на Р/Р</span>
                    <input type="radio" name='discountType' checked={discountType === 'VAL'}
                           onClick={() => onChangeDiscount('VAL')} onChange={() => onChangeDiscount('VAL')}/>
                    <p>{UAHInputValue} грн</p>
                </div>
            </div>
            {popup && popup === 'PERC' && (
                <GlobalPopup title={'Знижка'} onClose={() => onClosePopup()}>
                    <GlobalCalculator handleChange={handlePercentChange} handleDelete={handlePercentDelete} onSubmit={() => onSubmitPopup('PERC')}>
                        <div className={styles.percentInputWrap}>
                            <input id='percent_discount' name="percent_discount" value={percentInputValue}
                                   className={'baseInput'} type="text" readOnly/><span>%</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
            {popup && popup === 'VAL' && (
                <GlobalPopup title={'Знижка'} onClose={() => onClosePopup()}>
                    <GlobalCalculator handleChange={handleUAHChange} handleDelete={handleUAHDelete} onSubmit={() => onSubmitPopup('VAL')}>
                        <div className={styles.UAHInputWrap}>
                            <input id='uah_discount' name="uah_discount" value={UAHInputValue}
                                   className={'baseInput'} type="text" readOnly/><span>&nbsp;грн</span>
                        </div>
                    </GlobalCalculator>
                </GlobalPopup>
            )}
        </div>
    )
}

export default DiscountField;