import React from "react";
import styles from './ChoosePopup.module.scss';
import FabricChooseItem from "./FabricChooseItem";

const ChoosePopup = ({fabricFilters, handleChange, selectedFabric}) => {
    return (
        <div className={styles.chooserPopup}>
            {fabricFilters && fabricFilters.length > 0 && (
                fabricFilters.map((item, index) => (
                    <FabricChooseItem key={index} item={item} handleChange={handleChange} selectedFabric={selectedFabric} />
                ))
            )}

        </div>
    )
}

export default ChoosePopup;