import React, { useEffect, useState } from "react";
import styles from './DeliveryNP.module.scss';
import { useFormik } from "formik";
import './delivery.scss'
import NovaPoshtaCities from "./NovaPoshtaCities/NovaPoshtaCities";
import NovaPoshtaDepartments from "./NovaPoshtaDepartments/NovaPoshtaDepartments";
import NovaPoshtaWeight from "./NovaPoshtaWeight/NovaPoshtaWeight";
import DeliveryIPay from "./DeliveryIPay/DeliveryIPay";
import copyIcon from "../../../assets/icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAccessToken, setDeliverNP, setDeliverNPTTN } from "../../../api/api";
import Parser from "../../Common/Parser/Parser";

const DeliveryNP = ({ deliveryOrder, lastOrderId }) => {
    const [city, setCity] = useState('');
    const [cityValue, setCityValue] = useState(deliveryOrder?.receiver?.delivery_receiver?.city || '');
    const [isIPay, setIsIPay] = useState(deliveryOrder?.delivery?.payer === 'Sender' ? true : false);
    const [saveData, setSaveData] = useState('');
    const [deliveryNPValues, setDeliveryNPValues] = useState(null);
    const [errors, setErrors] = useState({});

    const getDeliveryNPValues = (updatedDeliveryOrder) => {
        if (!updatedDeliveryOrder) return null;
        return {
            id: updatedDeliveryOrder?.id,
            first_name: updatedDeliveryOrder?.receiver?.delivery_receiver?.first_name || '',
            last_name: updatedDeliveryOrder?.receiver?.delivery_receiver?.last_name || '',
            phone: updatedDeliveryOrder?.receiver?.delivery_receiver?.phone || '',
            city: updatedDeliveryOrder?.receiver?.delivery_receiver?.city || '',
            city_ref: updatedDeliveryOrder?.receiver?.city_identifier || '',
            address: updatedDeliveryOrder?.receiver?.address || '',
            address_ref: updatedDeliveryOrder?.receiver?.address_identifier || '',
            max_weight_allowed: updatedDeliveryOrder?.receiver?.max_weight_allowed || '',
            warehouse_number: updatedDeliveryOrder?.receiver?.department_number || '',
            specified_weight: updatedDeliveryOrder?.specified_weight || '',
            payer: deliveryOrder?.delivery?.payer || '',
            estimate_order_price: updatedDeliveryOrder?.delivery?.estimate_order_price || '',
        }
    }

    useEffect(() => {
        if (deliveryOrder) {
            setDeliveryNPValues(getDeliveryNPValues(deliveryOrder));
        }
    }, [deliveryOrder])

    const auditDates = (value, fieldName = null) => {
        if (saveData !== value) {
            getAccessToken().then((token) => {
                if (fieldName) {
                    setDeliverNP(token, lastOrderId, { [fieldName]: value }).then((res) => {
                        if (res.errors) {
                            setErrors(prevErrors => ({ ...prevErrors, [fieldName]: res.errors[fieldName] }));
                        } else {
                            setDeliveryNPValues(getDeliveryNPValues(res));
                            setErrors(prevErrors => {
                                const { [fieldName]: removedError, ...restErrors } = prevErrors;
                                return restErrors;
                            });
                        }
                    })
                } else {
                    setDeliverNP(token, lastOrderId, value).then((res) => {
                        if (res.errors) {
                            setErrors(prevErrors => ({ ...prevErrors, ...res.errors }));
                        } else {
                            setDeliveryNPValues(getDeliveryNPValues(res));
                            const restErrors = { ...errors };
                            Object.keys(value).forEach(key => {
                                if (key in restErrors) {
                                    delete restErrors[key];
                                }
                            })
                            setErrors(restErrors);
                        }
                    })
                }
            });
        }
    }

    const formValues = {
        first_name: deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.first_name ? deliveryOrder.receiver.delivery_receiver.first_name : '',
        last_name: deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.last_name ? deliveryOrder.receiver.delivery_receiver.last_name : '',
        phone: deliveryOrder.receiver && deliveryOrder.receiver.delivery_receiver && deliveryOrder.receiver.delivery_receiver.phone ? deliveryOrder.receiver.delivery_receiver.phone : '',
        declared_order_price: deliveryOrder.delivery && deliveryOrder.delivery.estimate_order_price ? deliveryOrder.delivery.estimate_order_price : '',
        declared_delivery_price: deliveryOrder.delivery && deliveryOrder.delivery.estimate_delivery_price ? deliveryOrder.delivery.estimate_delivery_price : '',
        ttn: deliveryOrder.delivery && deliveryOrder.delivery.ttn ? deliveryOrder.delivery.ttn : '',
        comment: deliveryOrder.delivery && deliveryOrder.delivery.comment ? deliveryOrder.delivery.comment : '',
    }
    const { handleSubmit, handleChange, values, setValues } = useFormik({
        initialValues: formValues,

        onSubmit: (values) => {
            console.log(values)
        },
    });

    const handleSetValues = (newValues) => {
        setValues(prevValues => ({
            ...prevValues,
            first_name: newValues.first_name,
            last_name: newValues.last_name,
            phone: newValues.phone,
        }));
        setCityValue(newValues.city);
    }

    const validateRequiredNPValues = () => {
        const newErrors = {};

        for (const [key, value] of Object.entries(deliveryNPValues)) {
            if (!value) {
                newErrors[key] = 'This field may not be blank.';
            }
        }

        if (Object.keys(errors).length > 0 || Object.keys(newErrors).length > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, ...newErrors}));
            return false;
        }

        return true;
    }

    const getTTN = () => {
        if (!deliveryOrder) return;
        const isValid = validateRequiredNPValues();
        if (!isValid) return;

        getAccessToken().then((token) => {
            setDeliverNPTTN(token, deliveryNPValues.id).then((response) => {
                if (response.errors) {
                    console.log(response?.errors?.ttn);
                } else {
                    setValues((prevValues) => ({
                        ...prevValues,
                        ttn: response?.delivery?.ttn,
                        declared_delivery_price: response?.delivery?.estimate_delivery_price
                    }));
                }
            }).catch((error) => {
                console.log(error);
            });
        });
    }

    return (
        <div className={`${styles.deliveryNP} deliveryNP`}>
            <form onSubmit={handleSubmit}>
                <div className={styles.deliveryNP__content}>
                    <div className={styles.deliveryNP__row}>
                        <input
                            placeholder={'І`мя Отримувача'}
                            className={`baseInput ${errors.first_name ? 'baseInputError' : ''}`}
                            id="first_name"
                            name="first_name"
                            type="text"
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            value={values.first_name}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'first_name')}
                        />
                        <input
                            placeholder={'Прізвище Отримувача'}
                            className={`baseInput ${errors.last_name ? 'baseInputError' : ''}`}
                            id="last_name"
                            name="last_name"
                            type="text"
                            onChange={handleChange}
                            value={values.last_name}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'last_name')}
                        />
                    </div>
                    <div>
                        <input
                            placeholder={'Телефон Отримувача'}
                            className={`baseInput ${errors.phone ? 'baseInputError' : ''}`}
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={handleChange}
                            value={values.phone}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'phone')}
                        />
                    </div>
                    <div className={styles.deliveryNP__row}>
                        <NovaPoshtaCities
                            setCity={setCity}
                            cityValue={cityValue}
                            setCityValue={setCityValue}
                            updateData={auditDates}
                            error={errors.city}
                        />
                        <NovaPoshtaDepartments
                            city={city}
                            department={deliveryNPValues?.address || ''}
                            updateData={auditDates}
                            error={errors.address}
                        />
                    </div>
                    <NovaPoshtaWeight
                        weight={deliveryNPValues?.specified_weight || ''}
                        setWeight={(specified_weight) => setDeliveryNPValues(prevValues => ({ ...prevValues, specified_weight }))}
                        auditDates={auditDates}
                        error={errors.specified_weight}
                    />
                    <DeliveryIPay isIPay={isIPay} setIsIPay={setIsIPay} auditDates={auditDates}/>
                    <div>
                        <input
                            placeholder={'Оголошена вартість'}
                            className={`baseInput ${errors.estimate_order_price ? 'baseInputError' : ''}`}
                            id="declared_order_price"
                            name="declared_order_price"
                            type="number"
                            onChange={handleChange}
                            value={values.declared_order_price}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'estimate_order_price')}
                        />
                    </div>
                    <div className={styles.closedInput}>
                        <input
                            placeholder={'Орієнтовна Вартість Доставки'}
                            className={'baseInput'}
                            id="declared_delivery_price"
                            name="declared_delivery_price"
                            type="number"
                            value={values.declared_delivery_price}
                            readOnly
                        />
                    </div>
                    <div className={styles.transportNumber}>
                        <input
                            placeholder={'ТТН'}
                            className={'baseInput'}
                            id="ttn"
                            name="ttn"
                            type="number"
                            value={values.ttn}
                            readOnly
                            onClick={() => getTTN()}
                        />
                        <CopyToClipboard text={values.ttn} className={styles.copyButton}>
                            <button type='button'>
                                <img src={copyIcon} alt="" />
                            </button>
                        </CopyToClipboard>
                    </div>
                    <div>
                        <textarea
                            cols="30"
                            rows="10"
                            placeholder={'Коментар'}
                            id="comment"
                            name="comment"
                            onChange={handleChange}
                            value={values.comment}
                            onFocus={(e) => setSaveData(e.target.value)}
                            onBlur={(e) => auditDates(e.target.value, 'comment')}
                        />
                    </div>
                    <div className={styles.action}>
                        <button className={'globalBtn'} type='submit'>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </form>
            <Parser
                values={{
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone: values.phone,
                    city: cityValue,
                }}
                setValues={handleSetValues}
                updateData={auditDates}
                parserStyles={{ backgroundColor: '#F0B5B7' }}
            />
        </div>
    )
}

export default DeliveryNP;