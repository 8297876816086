import React from "react";
import styles from './PaymentDone.module.scss'
import doneIcon from "../../../../assets/icons/done.svg";
import Link from "../../Status/Link/Link";
import {useNavigate} from "react-router";

const PaymentDone = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.done}>
            <img src={doneIcon} className={styles.done__check} alt=""/>
            <h2>Оплата успішно проведена!<br/>Дякуємо за замовлення!</h2>
            <button onClick={() => navigate(-2)} className={styles.done__back}>Повернутись до замовлення</button>
            <Link/>
        </div>
    )
}

export default PaymentDone;