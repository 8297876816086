import React from "react";
import styles from '../Payment.module.scss';
import mono from '../../../../../assets/icons/monobank.png';
import {sendPayMonobank} from "../../../../../api/api";



const Button = ({payment}) => {
    const onSend = () => {
        if (payment?.id) (
            sendPayMonobank(payment.id).then((response) => {
                if (response && response.invoice_url) {
                    window.location.href = response.invoice_url;
                }
            })
        )
    }

    return (
        <button className={styles.paymentBtn} onClick={() => onSend()}>
            <img src={mono} alt=""/>
        </button>
    )
}

export default Button;