import React, {useEffect} from "react";
import styles from './SearchStorage.module.scss';
import searchIcon from "../../../assets/icons/search.svg";
import {getAccessToken, getSearchResult, getStorageProducts} from "../../../api/api";


const SearchStorage = ({searchValue, setSearchValue, setPage, setTotalPages, setStorageList}) => {
    let timeout;

    const sendSearch = (value = null, page) => {
        getAccessToken().then((token) => {
            getSearchResult(token, searchValue,  true, true, true, page).then((resp) => {
                if (resp && resp.warehouse_items && resp.warehouse_items.items && resp.warehouse_items.items.length > 0) {
                    setStorageList(resp.warehouse_items.items);
                    setPage(resp.warehouse_items.current_page);
                    setTotalPages(resp.warehouse_items.total_pages)
                } else {
                    console.log(resp)
                }
            })
        })
    }

    useEffect(() => {
        if (searchValue.length > 0) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                sendSearch(null, 1);
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        } else if (searchValue === '') {
            getAccessToken().then((token) => {
                getStorageProducts(token, 1).then((res) => {
                    if (res?.items && res.items.length > 0) {
                        setStorageList(res.items)
                    }
                    if (res?.total_pages) {
                        setTotalPages(res.total_pages)
                    }
                })
            })

        }
    }, [searchValue]);

    return (
        <div className={styles.searchStorage}>
            <input type="search" placeholder={'Пошук'} className={'baseInput'} value={searchValue}
                   onChange={(e) => setSearchValue(e.target.value)}/>
            <img src={searchIcon} alt=""/>
        </div>
    )
}

export default SearchStorage;