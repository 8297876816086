import {Route, Routes} from "react-router-dom";
import Auth from "./Auth/Auth";
import UserMenu from "../Navigations/UserMenu";
import React from "react";
import Contacts from "./Contacts/Contacts";
import Callback from "./Callback/Callback";
import Status from "./Status/Status";
import DetailsPayment from "./DetailsPayment/DetailsPayment";
import Requisites from "./Requisites/Requisites";
import FinishOrder from "./FinishOrder/FinishOrder";
import Fabric from "../UserPage/Fabric";

const ClientPage = () => {
    return (
        <>
            <Routes>
                <Route path={'/*'} element={<Auth/>}/>
                <Route path={'/orders/*'} element={<Status />} />
                <Route path={'/contacts'} element={<Contacts />} />
                <Route path={'/details_payment'} element={<DetailsPayment />} />
                <Route path={'/callback'} element={<Callback />} />
                <Route path={'/requisites'} element={<Requisites/>} />

                <Route path={'/user_fabric/*'} element={<Fabric/>} />
                <Route path={'/monobank/finish-order/*'} element={<FinishOrder />} />
                <Route path={'/wayforpay/finish-order/*'} element={<FinishOrder />} />
            </Routes>
            <UserMenu/>
        </>
    )
}

export default ClientPage;