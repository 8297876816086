import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../hooks/redux";
import {getLastOrder} from "../../../store/selectors";
import {getAccessToken, getDeliverNP, setDeliverNP} from "../../../api/api";
import DeliveryNP from "./DeliveryNP";


const DeliveryNPWrapper = () => {
    const lastOrderId = useAppSelector(getLastOrder);
    const [deliveryOrder, setDeliveryOrder] = useState(null);

    useEffect(() => {
        if (lastOrderId) {
            getAccessToken().then((token) => {
                getDeliverNP(token, lastOrderId).then((result) => {
                    if (result && result.length > 0) {
                        setDeliveryOrder(result[0]);
                    } else if (result && result.detail) {
                        setDeliverNP(token, lastOrderId, {'paid_by_the_sender': false}).then((res) => {
                            if (res) {
                                setDeliveryOrder(res);
                            }
                        })
                    }
                })
            });
        }
    }, [lastOrderId])

    return (
        <>
            {deliveryOrder && (
                <DeliveryNP deliveryOrder={deliveryOrder} lastOrderId={lastOrderId} />
            )}
        </>
    )
}

export default DeliveryNPWrapper;