import React, {useEffect} from "react";
import {useLocation} from "react-router";
import queryString from "query-string";
import PaymentDone from "./PaymentDone/PaymentDone";
import {setFinishOrder} from "../../../api/api";


const FinishOrder = () => {
    const params = useLocation();
    const urlString = params.search;
    const hash = queryString.parse(urlString);

    useEffect(() => {
        if (urlString && hash?.invoice_id) {
            setFinishOrder(hash?.invoice_id).then((resp) => console.log(resp))
        }
    }, [])


    return (
        <div>
            <PaymentDone />
        </div>
    )
}

export default FinishOrder;