import React from "react";
import styles from '../ProductItem.module.scss';
import ImgPlaceholder from "../../../../assets/img/placeholder.svg";

const ClosedItem = ({ name = 'Невідомий продукт', price = 'Не доступно', images = [] }) => {
    return (
        <React.Fragment>
            <div className={styles.closedWrap}>
                <img src={!images[0]? ImgPlaceholder : images[0]} alt=""/>
                <div>
                    <p className={styles.closedWrap__title}>{name}</p>
                    <p>{price}</p>
                </div>

            </div>
        </React.Fragment>
    )
}

export default ClosedItem;