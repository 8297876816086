import React from "react";
import styles from '../../FabricSet.module.scss';

const FabricSetItemSize = ({item, setContainerPosition}) => {

    return (
        <div className={styles.size}>
            <div className={`${styles.size__item} ${styles.active}`} onClick={() => setContainerPosition(2)}>
                <p>{item?.size?.name || item?.size}</p>
            </div>
        </div>
    )
}

export default FabricSetItemSize;