import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {getAccessToken, getOrderInfo} from "../../../api/api";
import OrderCreate from "./OrderCreate";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getLastOrder} from "../../../store/selectors";
import {setLastOrders} from "../../../store/main-slice";


const OrderWrapper = () => {
    const {id} = useParams();
    const lastOrderId = useAppSelector(getLastOrder);
    const [order, setOrder] = useState(null);
    const dispatch = useAppDispatch()

    const updateOrder = (value) => {
        getAccessToken().then((token) => {
            getOrderInfo(token, value).then((response) => {
                if (response && response.id) {
                    setOrder(response);
                }
            })
        })
    }
    useEffect(() => {
        if (id) {
            updateOrder(id);
            dispatch(setLastOrders(id))
        } else if (!id && lastOrderId) {
            updateOrder(lastOrderId);
        }
    }, [id, lastOrderId])


    return (
        <>
            {order && (
                <OrderCreate order={order}/>
            )}
        </>
    )
}

export default OrderWrapper;