import React, {useEffect, useState} from "react";
import styles from './Fabric.module.scss';
import ImgPlaceholder from '../../assets/img/FabricImgPlaceholder.png';
import FabricSlider from "./FabricSlider";
import {useLocation} from "react-router";
import queryString from "query-string";
import {getFabricItem} from "../../api/api";

const Fabric = () => {
    const params = useLocation();
    const urlString = params.search;
    const hash = queryString.parse(urlString);
    const [fabric, setFabric] = useState(null);

    let {name = 'Невідома назва', type: {type = 'Невідомий тип'} = {}, images = []} = fabric || {};

    useEffect(() => {
        if (urlString && hash?.id) {
            getFabricItem(hash.id).then((response) => {
                if (response) {
                    setFabric(response)
                }
            })
        }
    }, [])

    return (
        <div className={styles.fabric}>
            {images.length > 1 ? <FabricSlider images={images}/> : <div className={styles.imgPlaceholder}>
                <img src={!images.length || !images[0] ? ImgPlaceholder : images[0]}
                     className={!images.length || !images[0] ? '' : styles.mainImg}
                     alt=""/>
            </div>}
            <div className={images.length > 1 ? `${styles.tableWrap} ${styles.withSlider}` : styles.tableWrap}>
                <h3>{name} {type}</h3>
                <div className={styles.table}>
                    <div><span>Назва</span></div>
                    <div><span>Вартість</span></div>
                    <div><span>Доступно(шт).</span></div>
                    <div><span>1.5</span></div>
                    <div><span>1800 грн</span></div>
                    <div><span>11 шт.</span></div>
                    <div><span>2.0</span></div>
                    <div><span>1900 грн</span></div>
                    <div><span>16 шт.</span></div>
                    <div><span>Євро</span></div>
                    <div><span>2400 грн</span></div>
                    <div><span>12 шт.</span></div>
                    <div><span>Євро Maxi</span></div>
                    <div><span>2800 грн</span></div>
                    <div><span>8 шт.</span></div>
                    <div><span>Сімейний</span></div>
                    <div><span>2300 грн</span></div>
                    <div><span>10 шт.</span></div>
                </div>
                <p>Доступно в компанії</p>
            </div>
        </div>
    )
}

export default Fabric;