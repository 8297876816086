import React, {useEffect, useState} from "react";
import styles from '../../FabricSet/FabricSet.module.scss';
import closeIcon from "../../../../../assets/icons/closeCross.svg";
import PopupCheckboxes from "../../../../Common/PopupCheckboxes/PopupCheckboxes";

const OptionPopup = ({show, close, item, chosenOptions, setChosenOptions, onChangeOptions, componentIndex}) => {

    let optionsInfo = item?.options || [];
    const [initialOptions, setInitialOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);


    useEffect(() => {
        let formattedOptions = [];
        let formattedChosenOptions = [];
        if (optionsInfo) {
            if (Array.isArray(optionsInfo)) {
                formattedOptions = optionsInfo.map((el) => {
                    return {value: `${el.name}`, title: `${el.name}`, id: Number(el.id)}
                });
            }
            if (Array.isArray(chosenOptions)) {
                formattedChosenOptions = chosenOptions.map((el) => {
                    return {value: `${el.name}`, title: `${el.name}`, id: Number(el.id)}
                });
            }
            setInitialOptions(formattedOptions);
            setItems(formattedChosenOptions);
        }

    }, [item]);


    useEffect(() => {
        if (!firstLoad) {
            let formattedItems = items.map((el) => ({
                name: el.value,
                id: Number(el.id),
            }));
            setChosenOptions(formattedItems);
            let changedOptions = items.map((el) => el.id);
            onChangeOptions(changedOptions, componentIndex, 'option', 'option_array');
        }

        setFirstLoad(false);

    }, [items])

    return (
        <div
            className={`${show ? `${styles.globalPopupActive} ${styles.topPopup}` : styles.globalPopup}`}
            onClick={(e) => e.stopPropagation()}>
            <div className={styles.header}>
                <p className={styles.header__title}>Опції:</p>
                <div className={styles.header__close} onClick={() => close()}>
                    <span>Закрити</span>
                    <button>
                        <img src={closeIcon} alt=""/>
                    </button>
                </div>
            </div>
            <div className={styles.globalPopup__content}>
                <PopupCheckboxes values={items} setValues={setItems} chooseList={initialOptions}/>
            </div>
        </div>
    )
}

export default OptionPopup