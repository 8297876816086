import React, {useEffect, useState} from "react";
import styles from './Calculator.module.scss';
import FabricType from "./FabricType/FabricType";
import FabricSize from "./FabricSize/FabricSize";
import FabricOptions from "./FabricOptions/FabricOptions";
import FabricSet from "./FabricSet/FabricSet";
import CalcButton from "./CalcButton/CalcButton";
import FabricPrice from "./FabricPrice/FabricPrice";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import {getAccessToken, getFabricTemplate, setKit} from "../../../api/api";
import FabricSizeSecond from "./FabricSize/FabricSizeSecond";
import AddCommentButton from "./CommentButton/AddCommentButton";
import KitCommentPopup from "./Popups/CommentPopup/KitCommentPopup";
import AddComponentButton from "./ComponentButton/AddComponentButton";
import ComponentsPopup from "./Popups/ComponentsPopup/ComponentsPopup";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

const Calculator = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [showKitCommentPopup, setShowKitCommentPopup] = useState(false);
    const [showComponentPopup, setShowComponentPopup] = useState(false);
    const [template, setTemplate] = useState(null);
    const [size, setSize] = useState(null);
    const [customTemplateSize, setCustomTemplateSize] = useState(null);
    const [components, setComponents] = useState(null);
    const [fabric, setFabric] = useState(null);
    const dispatch = useAppDispatch();
    const [product, setProduct] = useState(null);
    const [form, setForm] = useState(null);
    const [kitCommentText, setKitCommentText] = useState("");
    const [customComponents, setCustomComponents] = useState([]);
    const [selectedOptionIds, setSelectedOptionIds] = useState([]);

    useEffect(() => {
        if (fabric && size) {
            getAccessToken().then((token) => {
                getFabricTemplate(token, fabric.id, size.id).then((resp) => {
                    if (resp && resp.kit) {
                        console.log(resp)
                        setProduct(resp)
                    } else {
                        setForm(null);
                        setProduct(null);
                        setComponents(null);
                        setCustomComponents([]);
                        setKitCommentText("");
                        setSelectedOptionIds([])
                    }
                })
            })
        }

    }, [fabric, size])

    const calcSave = () => {
        let isEdit = !!id;
        if (!isEdit) {
            const updatedForm = {
                ...form,
                comment: kitCommentText,
                additional_components: customComponents.map(item => item.id),
                option: [...selectedOptionIds],
                custom_size: customTemplateSize
            };
            setForm(updatedForm);
            getAccessToken().then((token) => {
                setKit(token, updatedForm).then((res) => {
                    console.log(res);
                    navigate(-1);
                });
            })
        } else {
            console.log('edit save');
            const updatedForm = {
                ...form,
                comment: kitCommentText,
                additional_components: customComponents.map(item => item.id),
                option: [...selectedOptionIds],
                custom_size: customTemplateSize
            };
            setForm(updatedForm);

            const formattedEditForm = {};

            let templateComponents = [];
            templateComponents = {...updatedForm}.template_components.map((el) => {
                return {
                    id: el.component_template_id,
                    custom_size : el?.custom_size === undefined ? null : el?.custom_size,
                    option: el.option,
                    comment: el?.comment
                }
            });
            console.log(templateComponents);
            formattedEditForm.component = templateComponents;
            formattedEditForm.additional_components = customComponents.map(item => item.id);
            formattedEditForm.custom_size = customTemplateSize;
            formattedEditForm.comment = kitCommentText;
            formattedEditForm.option = [...selectedOptionIds];
            console.log(formattedEditForm);
            //send to server formattedEditForm
        }
    }

    const openKitCommentPopup = () => {
        setShowKitCommentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const onSaveKitComment = () => {
        setShowKitCommentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    const openComponentPopup = () => {
        setShowComponentPopup(true);
        dispatch(setIsActivePopup(true));
    }

    const addCustomComponent = (newComponent) => {
        setCustomComponents(prevComponents => [...prevComponents, newComponent]);
        setShowComponentPopup(false);
        dispatch(setIsActivePopup(false));
    }

    return (
        <div className={styles.calculator}>
            <h2>Тип тканини</h2>
            <FabricType setTemplate={setTemplate} fabric={fabric} setFabric={setFabric} setComponents={setComponents}
                        id={id} setProduct={setProduct} setKitCommentText={setKitCommentText} setForm={setForm}
                        product={product} isEdit={!!id}/>
            <h2>Розмір комплекту</h2>
            <FabricSize kitTemplateSizes={template?.kit_template_size} setKitSize={setSize}
                        setCustomTemplateSize={setCustomTemplateSize} setKitCommentText={setKitCommentText}
                        isEdit={!!id}/>
            {product && product.kit && (
                <FabricSizeSecond setComponents={setComponents} setForm={setForm} product={product} isEdit={!!id}/>
            )}
            {components && (
                <>
                    <h2 className={styles.setTitle}>Комплект</h2>
                    <FabricSet components={components.components} setComponents={setComponents}
                               customComponents={customComponents} setCustomComponents={setCustomComponents}
                               kitId={components.id} types={fabric?.mono_fabric_type} form={form} setForm={setForm}
                               isEdit={!!id}/>
                    <div className={styles.bottomKitButtons}>
                        <AddCommentButton onClick={openKitCommentPopup}/>
                        <AddComponentButton onClick={openComponentPopup}/>
                    </div>
                </>
            )}
            {product && product.kit_options && (
                <h2>Опції</h2>
            )}
            {product && product.kit_options && (
                <FabricOptions options={product.kit_options} selectedOptionIds={selectedOptionIds}
                               setSelectedOptionIds={setSelectedOptionIds}/>
            )}
            <CalcButton action={calcSave} isDisabled={!components}/>
            {components?.price_retail && (
                <FabricPrice price={components.price_retail}/>
            )}
            {showKitCommentPopup && (
                <GlobalPopup topPopup title={'Коментар'} onClose={() => setShowKitCommentPopup(false)}>
                    <KitCommentPopup kitCommentText={kitCommentText} setKitCommentText={setKitCommentText}
                                     onSaveKitComment={onSaveKitComment}/>
                </GlobalPopup>
            )}
            {showComponentPopup && (
                <GlobalPopup topPopup title={'Комплектуючі:'} onClose={() => setShowComponentPopup(false)}>
                    <ComponentsPopup addCustomComponent={addCustomComponent}/>
                </GlobalPopup>
            )}
        </div>
    )
}

export default Calculator;