import React from "react";
import styles from './Order.module.scss';


const UrgentlyField = ({setIsUrgently, isUrgently, auditDates}) => {

    const onChangeUrgently = (value) => {
        auditDates(value, 'is_approved', 'payment');
        setIsUrgently(value);
    }

    return (
        <div className={styles.urgentlyField}>
            <div className={styles.customCheckbox}>
                <input name={'urgentlyField'} type={'checkbox'} checked={isUrgently} onChange={() => onChangeUrgently(!isUrgently)} />
                <span/>
                <p>Терміново</p>
            </div>
        </div>
    )
}

export default UrgentlyField;