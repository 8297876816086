import React, {useState} from "react";
import styles from './Order.module.scss';
import arrow from "../../../assets/icons/arrowDownGray.svg";
import {useAppDispatch} from "../../../hooks/redux";
import {setIsActivePopup} from "../../../store/main-slice";
import GlobalPopup from "../../Common/GlobalPopup/GlobalPopup";
import PopupCheckboxes from "../../Common/PopupCheckboxes/PopupCheckboxes";

const StatusPay = ({setPopup, popup, status, setStatus, auditDates}) => {
    const dispatch = useAppDispatch();

    const chooseList = [
        {title: 'Оплачено', value: 'PAID'},
        {title: 'В обробці', value: 'IN_PROG'},
        {title: 'Очікує оплати', value: 'PAY_WAIT'},
        {title: 'Не оплачено', value: 'NOT_PAID'},
    ];

    const OnButton = (value) => {
        setPopup(value)
        setTimeout(() => {
            dispatch(setIsActivePopup(true))
        }, 30)
    }

    const onChangeStatus = (value) => {
        setStatus(value.value);
        auditDates(value.value, 'status', 'payment')
    }

    return (
        <>
            <button type='button' className={styles.statusPay} onClick={() => OnButton('statusPay')}>
                <span style={status ? {color: '#201827'} : null}>{
                    status && status === 'NOT_PAID' ? 'Не оплачено'
                        : status && status === 'PAY_WAIT' ? 'Очікує оплати'
                        : status && status === 'IN_PROG' ? 'В обробці'
                        : status && status === 'PAID' ? 'Оплачено'
                        : 'Статус оплати'
                }</span>
                <img src={arrow} alt=""/>
            </button>
            {popup && popup === 'statusPay' && (
                <GlobalPopup title={'Статус оплати'}>
                    <PopupCheckboxes values={status} setValues={onChangeStatus} chooseList={chooseList} isRadio />
                </GlobalPopup>
            )}
        </>
    )
}

export default StatusPay;