import React, {useState} from "react";
import styles from './ProductItem.module.scss';
import IconArr from "../../../assets/icons/arrowDown.svg";
import OpenItem from "./OpenItem/OpenItem";
import ClosedItem from "./ClosedItem/ClosedItem";

const ProductItem = ({isOpen, item, onDelete, isClient}) => {
    const [open, setOpen] = useState(isOpen);

    const product = {
        id: item.id,
        name: item.kit ? item.kit.name : item.warehouse_item.name,
        images: item.kit ? [] : item.warehouse_item.images,
        quantity: item.kit ? null : item.warehouse_item.quantity,
        size: item.kit ? null : item.warehouse_item.size,
        color: item.kit ? null : item.warehouse_item.color,
        price: item.kit ? item.kit.price_retail : item.warehouse_item.price,
        fabric_type: item.kit ? item.kit.fabric : null,
        kit_type: item.kit ? item.kit.type : null,
        components: item.kit ? item.kit.component : "",
        options: item.kit ? item.kit.option : []
    }

    const toggleOpen = () => {
        setOpen(prevOpen => !prevOpen);
    }

    const handleDeleteItem = (id) => {
        setOpen(false);
        onDelete(id);
    }

    return (
        <div className={styles.productItem}>
            {open? <OpenItem product={product} onDelete={handleDeleteItem} isClient={isClient}/> : <ClosedItem {...product}/>}
            <div className={styles.panel} onClick={toggleOpen}>
                <button>
                    <img src={IconArr} alt="" className={open? styles.arrUp : ''}/>
                </button>
            </div>
        </div>
    )
}

export default ProductItem;